import { Event, track } from '@rehold-io/data-layer-client';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import {
  OnrampFormProvider,
  OnrampProvider,
  OnrampQuoteProvider,
  OnrampTokensProvider,
} from 'entities/Onramp/lib/providers';

import { Box } from 'shared/ui';

import { OnrampContent } from './OnrampContent';
import { OnrampWidget } from './OnrampWidget';

export const OnrampTab: React.FC<{ onTabChange: (tab: string) => void; tab: string }> = observer(
  ({ onTabChange, tab }) => {
    const [submitResult, setSubmitResult] = useState<string | null>();

    return (
      <Box
        px={submitResult === null || submitResult ? 0 : { default: 12, desktop: 16, tablet: 16 }}
        py={submitResult === null || submitResult ? 0 : { desktop: 20, phone: 0, tablet: 16 }}
      >
        <OnrampProvider>
          <OnrampTokensProvider>
            <OnrampFormProvider>
              <OnrampQuoteProvider>
                {(submitResult === null || submitResult) && (
                  <OnrampWidget
                    url={submitResult}
                    onClose={() => {
                      track(Event.ONRAMP_BROWSER_CLOSED);
                      setSubmitResult(undefined);
                    }}
                  />
                )}
                {submitResult === undefined && (
                  <OnrampContent onChange={setSubmitResult} onTabChange={onTabChange} tab={tab} />
                )}
              </OnrampQuoteProvider>
            </OnrampFormProvider>
          </OnrampTokensProvider>
        </OnrampProvider>
      </Box>
    );
  },
);
