import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';

import { useLiveChatWidget } from 'features/LiveChat';

import { HeadsetIcon } from 'shared/assets';
import { useToggle } from 'shared/hooks';
import { Box } from 'shared/ui';

import styles from './styles.module.scss';

export const NavSupport = observer(() => {
  const [isOpen, , setOpen] = useToggle();
  const widget = useLiveChatWidget();

  const handleVisibilityChange = useCallback(
    (data: any) => {
      switch (data.visibility) {
        case 'maximized':
          setOpen(true);
          break;
        case 'minimized':
          break;
        default:
          setOpen(false);
          break;
      }
    },
    [setOpen],
  );

  useEffect(() => {
    if (widget) {
      widget.on('visibility_changed', handleVisibilityChange);
      return () => {
        widget.off('visibility_changed', handleVisibilityChange);
      };
    }
  }, [widget, handleVisibilityChange]);

  const handleOpen = useCallback(() => {
    if (isOpen) {
      widget?.call('minimize');
    } else {
      widget?.call('maximize');
    }
  }, [widget, isOpen]);

  return (
    <>
      <Box className={cx('menu-header-notifications', styles.trigger, { [styles.open]: isOpen })} onClick={handleOpen}>
        <HeadsetIcon width={24} height={24} />
      </Box>
    </>
  );
});
