import { Turnstile } from '@marsidev/react-turnstile';
import { Event, track } from '@rehold-io/data-layer-client';
import { differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns';
// eslint-disable-next-line import/order
import EventEmitter from 'events';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePoints } from 'widgets/Points/lib';

import { useGetPointsSurveyLinkQuery } from 'entities/Points';
import { useUserStore } from 'entities/User';

import { ClockIcon } from 'shared/assets';
import { useAccount } from 'shared/hooks';
import { LOCAL_STORAGE_SURVEY_CACHE, RECAPTCHA_ERROR, UNKNOWN_ERROR, extractError } from 'shared/lib';
import { logger } from 'shared/lib/logger';
import { notifyError } from 'shared/lib/notifications';
import { Box, Button, Text, Spinner } from 'shared/ui';

import { DailyQuestDiscordModal } from './DiscordModal';
import { DailyQuestSurveyModal } from './SurveyModal';
import { DailyQuestTwitterModal } from './TwitterModal';
import { ReactComponent as RedyIcon } from './assets/redy.svg';

const TURNSTILE_VERIFY_EVENT = 'verified';
const TURNSTILE_REJECT_EVENT = 'rejected';
const TURNSTILE_REJECT_ERROR = 'Turnstile failed CAPTCHA';
const TURNSTILE_TIMEOUT = 5000;
const TURNSTILE_TIMEOUT_ERROR = 'Timeout waiting for recaptcha';

export const PointsDailyQuest: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    settings,
    isSettingsLoading,
    stats,
    isStatsLoading,
    isStatsFetching,
    claim,
    isClaimDisabled,
    isClaimLoading,
  } = usePoints();

  const { address, isConnected } = useAccount();
  const { isLoading: isUserLoading, userInfo } = useUserStore();
  const { data: surveyData, isLoading: isSurveyLoading } = useGetPointsSurveyLinkQuery();

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [isOpenSurvey, setOpenSurvey] = useState(false);
  const [isOpenTwitter, setOpenTwitter] = useState(false);
  const [isOpenDiscord, setOpenDiscord] = useState(false);

  const calculateTimeLeft = useCallback(() => {
    if (!isClaimDisabled || !stats) return 0;

    const now = Date.now();
    const nextClaimAt = Date.parse(stats.nextClaimAt);

    setHours(differenceInHours(nextClaimAt, now));
    setMinutes(differenceInMinutes(nextClaimAt, now) % 60);
    setSeconds(differenceInSeconds(nextClaimAt, now) % 60);
  }, [stats, isClaimDisabled]);

  useEffect(() => {
    calculateTimeLeft();

    const interval = setInterval(() => {
      calculateTimeLeft();
    }, 1000);

    return () => clearInterval(interval);
  }, [calculateTimeLeft]);

  const turnstileEmitter = useMemo(() => new EventEmitter(), []);

  const [isTurnstileLoading, setTurnstileLoading] = useState(false);
  const [turnstile, setTurnstile] = useState<string | undefined>();

  const handleCaptchaTurnstile = useCallback(
    (token: string) => {
      setTurnstile(token);
      turnstileEmitter.emit(TURNSTILE_VERIFY_EVENT, token);
    },
    [turnstileEmitter],
  );

  const handleCaptchaTurnstileError = useCallback(() => {
    setTurnstile(undefined);
    turnstileEmitter.emit(TURNSTILE_REJECT_EVENT);
  }, [turnstileEmitter]);

  const handleClaim = useCallback(async () => {
    try {
      // let captcha = turnstile;

      // if (!captcha) {
      //   setTurnstileLoading(true);

      //   let timeout: NodeJS.Timeout | undefined;

      //   captcha = await Promise.race([
      //     new Promise<string>((resolve) => {
      //       turnstileEmitter.on(TURNSTILE_VERIFY_EVENT, (token: string) => resolve(token));
      //     }),
      //     new Promise<string>((_, reject) => {
      //       turnstileEmitter.on(TURNSTILE_REJECT_EVENT, () => reject(new Error(TURNSTILE_REJECT_ERROR)));
      //     }),
      //     new Promise<string>((_, reject) => {
      //       timeout = setTimeout(() => reject(new Error(TURNSTILE_TIMEOUT_ERROR)), TURNSTILE_TIMEOUT);
      //     }),
      //   ]).finally(() => {
      //     if (timeout) clearTimeout(timeout);
      //     setTurnstileLoading(false);
      //   });
      // }

      await claim({ turnstile: '' });
    } catch (error: any) {
      const errorMessage: string = error?.response?.data?._error?.message || error?.message;

      if (
        errorMessage === RECAPTCHA_ERROR ||
        errorMessage === TURNSTILE_TIMEOUT_ERROR ||
        errorMessage === TURNSTILE_REJECT_ERROR
      ) {
        notifyError({
          text: t('points.captcha.failed'),
        });

        track(Event.POINT_CLAIM_FAILED, {
          address: address!,
          amount: settings?.daily || 0,
          error: extractError(error),
        });
      } else {
        notifyError({
          text: errorMessage || UNKNOWN_ERROR,
        });

        track(Event.POINT_CLAIM_FAILED, {
          address: address!,
          amount: settings?.daily || 0,
          error: extractError(error),
        });

        logger.error(error);
      }
    }
  }, [turnstileEmitter, claim]);

  const isSurveyShown = useCallback(() => {
    const cache = JSON.parse(localStorage.getItem(LOCAL_STORAGE_SURVEY_CACHE) || '{}');
    const addressCache = cache[address ?? ''] ?? {};
    return !!addressCache[surveyData?.url ?? ''];
  }, [address, surveyData]);

  const isDisabled = !isConnected || isClaimLoading || isStatsFetching || isClaimDisabled || isSurveyLoading;

  if ((isConnected && isStatsLoading) || isSettingsLoading) {
    return (
      <Box height={250} alignItems="center" justifyContent="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      <DailyQuestSurveyModal isOpen={isOpenSurvey} onClose={() => setOpenSurvey(false)} />
      <DailyQuestTwitterModal isOpen={isOpenTwitter} onClose={() => setOpenTwitter(false)} />
      <DailyQuestDiscordModal isOpen={isOpenDiscord} onClose={() => setOpenDiscord(false)} />
      {/* <Turnstile
        siteKey={process.env.REACT_APP_CLOUDFLARE_CAPTCHA_SITEKEY!}
        options={{
          theme: 'dark',
          size: 'invisible',
          appearance: 'interaction-only',
        }}
        onSuccess={(token) => handleCaptchaTurnstile(token)}
        onError={() => handleCaptchaTurnstileError()}
      /> */}
      {/* <ReCaptchaV3 onVerify={handleCaptchaTurnstile} refreshReCaptcha={false} /> */}
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        borderColor="secondary-02"
        borderRadius={16}
        height={64}
        p={8}
        mb={24}
        sx={{ borderWidth: 1 }}
      >
        <Box flex={1} flexDirection="column" ml={16}>
          <Text mb={2}>{t('points.quest.dailyQuest')}</Text>
          <Box flex={1} flexDirection="row">
            <Box flexDirection="row" alignItems="center">
              {isClaimDisabled && (
                <>
                  <Box mr={8} color="gray-01">
                    <ClockIcon width={16} height={16} />
                  </Box>
                  <Text color="gray-01">
                    {[
                      String(hours).padStart(2, '0'),
                      String(minutes).padStart(2, '0'),
                      String(seconds).padStart(2, '0'),
                    ].join(':')}
                  </Text>
                </>
              )}
              {!isClaimDisabled && (
                <>
                  <Box mr={8} color="primary-01">
                    <RedyIcon />
                  </Box>
                  <Text color="primary-01">
                    {`${settings?.daily} `}
                    <Text color="primary-01" opacity={0.6}>
                      REDY
                    </Text>
                  </Text>
                </>
              )}
            </Box>
          </Box>
        </Box>
        <Button
          loading={(isConnected && (isUserLoading || isSurveyLoading)) || isClaimLoading}
          disabled={isDisabled}
          onClick={() => {
            if (surveyData?.url && !isSurveyShown()) {
              setOpenSurvey(true);
              return;
            }
            if (!userInfo?.twitterUrl) {
              setOpenTwitter(true);
              return;
            }
            if (!userInfo?.discordUrl) {
              setOpenDiscord(true);
              return;
            }
            handleClaim();
          }}
          data-id="claim-points"
          maxWidth="max-content"
        >
          {t('points.quest.claim')}
        </Button>
      </Box>
    </>
  );
});
