import { Event, track } from '@rehold-io/data-layer-client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserStore } from 'entities/User';

import { EditIcon } from 'shared/assets';
import { Box, Text } from 'shared/ui';

import { SubscribeForm } from '../SubscribeForm';

export const NotificationSubscribeCard = () => {
  const { t } = useTranslation();

  const [isEdit, setIsEdit] = useState(false);
  const { userInfo } = useUserStore();

  const isExactEmail = userInfo?.email;

  const handleEdit = () => {
    track(Event.NOTIFICATION_EMAIL_EDITED, { from: 'notification' });
    setIsEdit(true);
  };

  return (
    <Box px="20px" py="16px" backgroundColor="secondary-02" gap="12px">
      {!isExactEmail && (
        <>
          <Text color="white-01" text="app-14-regular" opacity={0.6}>
            {t('notifications.subscribeDescription')}
          </Text>
        </>
      )}

      {isExactEmail && !isEdit && (
        <Box flexDirection="row" justifyContent="space-between" alignItems="center" gap="4px">
          <Text color="white-01" text="app-12-regular" wordBreak="break-all">
            {userInfo?.email}
          </Text>
          <Box onClick={handleEdit}>
            <EditIcon width={16} height={16} />
          </Box>
        </Box>
      )}

      {(isEdit || !isExactEmail) && (
        <SubscribeForm
          fromLocation="notification"
          isEdit={isEdit}
          onCancel={() => setIsEdit(false)}
          onSuccess={() => setIsEdit(false)}
        />
      )}
    </Box>
  );
};
