import { Skeleton } from '@mui/material';
import { Event, track } from '@rehold-io/data-layer-client';
import { formatAmount, formatPrice } from '@rehold-io/formatters';
import BigNumber from 'bignumber.js';
import cx from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PerpetualsNetValueTooltip } from 'entities/Perpetual';
import {
  usePerpetualClosePosition,
  usePerpetualClosedPositions,
  usePerpetualOpenedPositions,
  usePerpetualTariffs,
} from 'entities/Perpetual/lib/hooks';
import { PerpetualOpenedPosition } from 'entities/Perpetual/model';
import { useRateInterval } from 'entities/Rates';
import { RateProduct } from 'entities/Rates/model/types';

import { DownIcon, UpIcon } from 'shared/assets';
import { useAccount } from 'shared/hooks';
import { formatCurrency, formatPercent, formatWithCommas } from 'shared/lib/formats/format';
import { notifyPosition } from 'shared/lib/notifications';
import { Box, Button, Text } from 'shared/ui';

import './index.scss';

export const PerpetualsListsPositions = () => {
  const { t } = useTranslation();

  const { isConnected } = useAccount();

  const { data, isLoading } = usePerpetualOpenedPositions();

  useState();

  return (
    <Box className="perpetuals-lists-positions">
      <Box className="perpetuals-lists-positions-header">
        <Box className="perpetuals-lists-positions-row">
          <Cell width="13%">
            <Text text="app-12-regular" color="gray-01">
              {t('perpetuals.lists.header.pair')}
            </Text>
          </Cell>
          <Cell width="18%">
            <Text text="app-12-regular" color="gray-01">
              {t('perpetuals.lists.header.amount')}
            </Text>
          </Cell>
          <Cell width="18%">
            <Text text="app-12-regular" color="gray-01">
              {t('perpetuals.lists.header.size')}
            </Text>
          </Cell>
          <Cell width="25%">
            <Text text="app-12-regular" color="gray-01">
              {t('perpetuals.lists.header.pnl')}
            </Text>
          </Cell>
          <Cell width="13%">
            <Text text="app-12-regular" color="gray-01">
              {t('perpetuals.lists.header.openPrice')}
            </Text>
          </Cell>
          <Cell width="13%">
            <Text text="app-12-regular" color="gray-01">
              {t('perpetuals.lists.header.liqPrice')}
            </Text>
          </Cell>
          <Cell width="72px" />
        </Box>
      </Box>
      <Box className="perpetuals-lists-positions-body">
        {isConnected && isLoading && (
          <Skeleton
            width={128}
            height={24}
            sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '4px', transform: 'scale(1, 1)', marginTop: 20 }}
            animation="wave"
          />
        )}
        {isConnected && !isLoading && data?.map((position) => <Item position={position} />)}
        {(!isConnected || (!isLoading && !data?.length)) && (
          <Box paddingTop={20}>
            <Text whiteSpace="nowrap"> {t('perpetuals.lists.noPositions')}</Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const Item: React.FC<{ position: PerpetualOpenedPosition }> = ({ position }) => {
  const { t } = useTranslation();

  const rate = useRateInterval({ from: position.baseTicker, to: position.quoteTicker, product: RateProduct.PERPETUAL });
  const quoteOutputRate = useRateInterval({
    from: position.quoteTicker,
    to: position.inputTicker,
    product: RateProduct.DEFAULT,
  });

  const { tariffs } = usePerpetualTariffs();
  const tariff = useMemo(
    () =>
      tariffs.find(
        (t) =>
          t.baseTicker === position.baseTicker &&
          t.quoteTicker === position.quoteTicker &&
          t.isShort === position.isShort,
      ),
    [tariffs],
  );

  const amount = BigNumber(position.inputAmount).minus(position.openingFeeAmount ?? 0);

  const size = BigNumber(position.inputAmount)
    .multipliedBy(position.leverage)
    .minus(position.openingFeeAmount ?? 0);

  const closedPrice = position.isShort ? rate.ask : rate.bid;
  const closingFeeAmount = BigNumber(position.collateralAmount)
    .times(position.isShort ? 1 : closedPrice)
    .times(tariff?.closingFee || 0)
    .multipliedBy(quoteOutputRate.price);

  const outputAmount = (
    position.isShort
      ? BigNumber(position.collateralAmount).minus(BigNumber(position.overdraftAmount).times(closedPrice))
      : BigNumber(position.collateralAmount).multipliedBy(closedPrice).minus(position.overdraftAmount)
  )
    .multipliedBy(quoteOutputRate.price)
    .minus(closingFeeAmount);

  const diff = outputAmount.minus(amount);
  const isPositive = diff.gt(0);
  const isNegative = diff.lt(0);

  const diffPercent = diff.div(amount);

  const { refetch: refetchOpened } = usePerpetualOpenedPositions();
  const { refetch: refetchClosed } = usePerpetualClosedPositions();

  const { closePosition, isLoading } = usePerpetualClosePosition({
    id: position.id,
    onSuccess: () => {
      refetchClosed();
      refetchOpened();

      notifyPosition({
        status: 'success',
        inputAmount: formatAmount({ value: amount, symbol: position.inputTicker, roundingMode: BigNumber.ROUND_DOWN }),
        inputTicker: position.inputTicker,
        isShort: position.isShort,
        leverage: position.leverage,
        type: 'close',
      });
    },
  });

  const handleCloseClick = () => {
    const trackParams = {
      ...position,
      outputTicker: position.inputTicker,
      outputAmount: outputAmount.toString(),
      closedPrice: closedPrice.toString(),
      closingFeeAmount: closingFeeAmount.toString(),
      openingFeeAmount: position.openingFeeAmount ?? 0,
    };

    closePosition(trackParams);
    track(Event.PERPETUAL_CLOSE_CLICKED, trackParams);
  };

  return (
    <Box className="perpetuals-lists-positions-row">
      <Cell>
        <Box className="perpetuals-lists-positions-cell-token">
          <img alt="token" src={`/static/tickers/${position.baseTicker}.svg`} />
          <Text text="app-14-regular">
            {position.baseTicker.toUpperCase()}/{position.quoteTicker.toUpperCase()}
          </Text>
        </Box>
      </Cell>
      <Cell>
        <Box className="perpetuals-lists-positions-cell-token">
          <Text text="app-14-regular">
            {formatWithCommas(
              formatAmount({ value: amount, symbol: position.inputTicker, roundingMode: BigNumber.ROUND_DOWN }),
            )}{' '}
            {position.inputTicker.toUpperCase()}
          </Text>
          <Box className="perpetuals-lists-positions-cell-leverage">
            {!position.isShort && <UpIcon width={16} height={16} />}
            {position.isShort && <DownIcon width={16} height={16} />}
            <Text text="app-12-regular" color="gray-01">
              x{position.leverage}
            </Text>
          </Box>
        </Box>
      </Cell>
      <Cell>
        <Text text="app-14-regular">
          {formatWithCommas(
            formatAmount({ value: size, symbol: position.inputTicker, roundingMode: BigNumber.ROUND_DOWN }),
          )}{' '}
          {position.inputTicker.toUpperCase()}
        </Text>
      </Cell>
      <Cell>
        <Box className="perpetuals-lists-positions-cell-pnl">
          {rate.ready && tariff && (
            <PerpetualsNetValueTooltip position={position}>
              <Text
                text="app-14-regular"
                whiteSpace="nowrap"
                className={cx({
                  'perpetuals-lists-positions-positive': isPositive,
                  'perpetuals-lists-positions-negative': isNegative,
                })}
              >{`${isPositive ? '+' : ''}${formatCurrency(diff.toNumber(), undefined, 2, 4).replace(
                '$',
                '',
              )} ${position.inputTicker.toUpperCase()} (${isPositive ? '+' : ''}${formatPercent({
                value: diffPercent,
                decimals: 2,
              })}%)`}</Text>
            </PerpetualsNetValueTooltip>
          )}
          {(!rate.ready || !tariff) && (
            <Text text="app-14-regular" whiteSpace="nowrap">
              -
            </Text>
          )}
        </Box>
      </Cell>
      <Cell>
        <Text text="app-14-regular">
          {formatWithCommas(
            formatPrice({ value: position.initialPrice, from: position.baseTicker, to: position.quoteTicker }),
          )}
        </Text>
      </Cell>
      <Cell>
        <Text text="app-14-regular">
          {formatWithCommas(
            formatPrice({ value: position.liquidationPrice, from: position.baseTicker, to: position.quoteTicker }),
          )}
        </Text>
      </Cell>
      <Cell>
        <Button
          loading={isLoading}
          disabled={isLoading}
          width={72}
          height={32}
          border="none"
          variant="primary-solid"
          onClick={handleCloseClick}
        >
          {t('perpetuals.lists.close')}
        </Button>
      </Cell>
    </Box>
  );
};

const Cell: React.CFC<{ className?: string; width?: string | number }> = ({ className, children, width }) => (
  <Box className="perpetuals-lists-positions-cell" width={width}>
    <Box className={className}>{children}</Box>
  </Box>
);
