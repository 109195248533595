import { formatAmount } from '@rehold-io/formatters';
import BN from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DualType, DualInputs } from 'entities/Dual';
import { DualTokenIcon, useGetDualTokensQuery } from 'entities/Token';

import { Box, BoxProps, Text } from 'shared/ui';

interface ClaimedDualProps extends BoxProps {
  dual: DualType;
  openClaimedDual: (id: string) => void;
}

export const ClaimedDual: React.FC<ClaimedDualProps> = observer(({ dual, openClaimedDual, ...rest }) => {
  const { t } = useTranslation();
  const { baseTicker, inputBaseAmount, inputQuoteAmount, outputAmount, outputTicker } = dual;
  const inputAmount = outputTicker === baseTicker ? inputBaseAmount : inputQuoteAmount;
  const profit = BN(outputAmount).minus(inputAmount);
  const { tokens } = useGetDualTokensQuery();

  const handlePress = useCallback(() => {
    openClaimedDual(dual.id);
  }, [dual, openClaimedDual]);

  const isReplayed = dual.l2AutoReplayed || dual.l2Replayed;

  return (
    <Box {...rest}>
      <Box onClick={handlePress} flexDirection="row" justifyContent="space-between">
        <DualInputs dual={dual} showPeriod={false} />
        <Box>
          <Box mb={2} flexDirection="row" alignItems="center" justifyContent="flex-end">
            <Text color="primary-01">
              +{formatAmount({ symbol: dual.outputTicker, value: profit })} {tokens[dual.outputTicker]?.symbol}
            </Text>
            <Box ml={8}>
              <DualTokenIcon ticker={dual.outputTicker} size={16} />
            </Box>
          </Box>
          <Box>
            <Text color="primary-01" sx={{ opacity: 0.4 }}>
              {formatAmount({ symbol: dual.outputTicker, value: dual.outputAmount })}{' '}
              {tokens[dual.outputTicker]?.symbol} {t(`dual.${isReplayed ? 'replayed' : 'claimed'}`)}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
