import { Event, track } from '@rehold-io/data-layer-client';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usePoints } from 'widgets/Points/lib';

import { GalxeIcon, HappyIcon, LimitOrder, NomisIcon, PeopleIcon, PerpetualsIcon, SwapIcon } from 'shared/assets';
import { PathPatterns, formatPercent } from 'shared/lib';
import { Box, Button, Spinner, Text } from 'shared/ui';

import styles from './HowItWorks.module.scss';

export const HowItWorks: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { settings, isSettingsError, isSettingsLoading, settingsRefetch } = usePoints();

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  if (isSettingsLoading) {
    return (
      <Box height={250} alignItems="center" justifyContent="center">
        <Spinner />
      </Box>
    );
  }

  if (!settings || isSettingsError) {
    return (
      <Box flexDirection="row" ml={8} alignSelf="center">
        <Box onClick={settingsRefetch}>
          <Text color="red-01" fontWeight="bold">
            {t('common.fetchRetry')}
          </Text>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box mb={18} alignItems="center" flexDirection="row">
        <Text text="app-22-medium">{t('points.howItWorks.title')}</Text>
      </Box>
      <Box position="relative">
        <Box width="100%" className={styles['reward-item-wrapper']}>
          <HowItWorksItem
            title={t('points.howItWorks.welcome.title')}
            badge={t('points.howItWorks.welcome.badge', { amount: settings.welcome })}
            description={t('points.howItWorks.welcome.description')}
            icon={<HappyIcon width={24} height={24} />}
          />
          <HowItWorksItem
            title={t('points.howItWorks.perpetuals.title')}
            badge={t('points.howItWorks.perpetuals.badge', { amount: settings.perpetual })}
            description={t('points.howItWorks.perpetuals.description', { amount: settings.perpetual })}
            button={t('points.howItWorks.perpetuals.button')}
            onButtonClick={() => {
              track(Event.POINT_STEP_CLICKED, { title: 'perpetual', path: PathPatterns.Perpetuals });
              navigate(PathPatterns.Perpetuals);
            }}
            icon={<PerpetualsIcon width={24} height={24} />}
          />
          <HowItWorksItem
            title={t('points.howItWorks.dual.title')}
            badge={t('points.howItWorks.dual.badge', { amount: settings.dual })}
            description={t('points.howItWorks.dual.description', { amount: settings.dual })}
            button={t('points.howItWorks.dual.button')}
            onButtonClick={() => {
              track(Event.POINT_STEP_CLICKED, { title: 'dual', path: PathPatterns.DualsDashboard });
              navigate(PathPatterns.DualsDashboard);
            }}
            icon={<LimitOrder width={24} height={24} />}
          />
          <HowItWorksItem
            title={t('points.howItWorks.swap.title')}
            badge={t('points.howItWorks.swap.badge', { amount: settings.swap })}
            description={t('points.howItWorks.swap.description', { amount: settings.swap })}
            button={t('points.howItWorks.swap.button')}
            onButtonClick={() => {
              track(Event.POINT_STEP_CLICKED, { title: 'swap', path: PathPatterns.Swap });
              navigate(PathPatterns.Swap);
            }}
            icon={<SwapIcon width={24} height={24} />}
          />
          {/*<HowItWorksItem*/}
          {/*  title={t('points.howItWorks.zealy.title')}*/}
          {/*  badge={t('points.howItWorks.zealy.badge')}*/}
          {/*  description={t('points.howItWorks.zealy.description')}*/}
          {/*  button={t('points.howItWorks.zealy.button')}*/}
          {/*  buttonType="info"*/}
          {/*  onButtonClick={() => {*/}
          {/*    track(*/}
          {/*      Event.POINT_STEP_CLICKED, ({ title: 'zealy', path: 'https://zealy.io/cw/rehold/questboard' }),*/}
          {/*    );*/}
          {/*    openInNewTab('https://zealy.io/cw/rehold/questboard');*/}
          {/*  }}*/}
          {/*  icon={<ZealyIcon width={24} height={24} />}*/}
          {/*/>*/}
          <HowItWorksItem
            title={t('points.howItWorks.galxe.title')}
            badge={t('points.howItWorks.galxe.badge')}
            description={t('points.howItWorks.galxe.description')}
            button={t('points.howItWorks.galxe.button')}
            buttonType="info"
            onButtonClick={() => {
              track(Event.POINT_STEP_CLICKED, {
                title: 'galxe',
                path: 'https://app.galxe.com/quest/rehold/GCHWqtgJqY',
              });
              openInNewTab('https://app.galxe.com/quest/rehold/GCHWqtgJqY');
            }}
            icon={<GalxeIcon width={24} height={24} />}
          />
          <HowItWorksItem
            title={t('points.howItWorks.nomis.title')}
            badge={t('points.howItWorks.nomis.badge')}
            description={t('points.howItWorks.nomis.description')}
            button={t('points.howItWorks.nomis.button')}
            buttonType="info"
            onButtonClick={() => {
              track(Event.POINT_STEP_CLICKED, {
                title: 'nomis',
                path: 'https://nomis.cc/scorefront-new?id=68',
              });
              openInNewTab('https://nomis.cc/scorefront-new?id=68');
            }}
            icon={<NomisIcon width={24} height={24} />}
          />
        </Box>
        <HowItWorksItem
          title={t('points.howItWorks.referral.title')}
          badge={t('points.howItWorks.referral.badge', {
            percent: formatPercent({
              value: settings.referral.reduce((acc, profit) => acc + profit, 0),
              decimals: 0,
            }),
          })}
          description={t('points.howItWorks.referral.description', {
            profit: formatPercent({
              value: settings.referral.reduce((acc, profit) => acc + profit, 0),
              decimals: 0,
            }),
            levels: settings.referral.length,
          })}
          icon={<PeopleIcon width={24} height={24} />}
          padding={2}
          margin={8}
        />
      </Box>
    </Box>
  );
};

const HowItWorksItem: React.FC<{
  badge: string;
  button?: string;
  buttonType?: 'action' | 'info';
  description: string;
  icon: ReactElement;
  margin?: number;
  onButtonClick?: () => void;
  padding?: number;
  title: string;
}> = ({ badge, button, buttonType, description, icon, title, margin, padding, onButtonClick }) => (
  <Box width="100%" className={styles['reward-item']}>
    <Box className={styles['reward-icon']}>{icon}</Box>
    <Box flexBasis={0} flexGrow={1} paddingBottom={button ? padding || 38 : 0}>
      <Text color="primary-01" text="app-18-medium">
        {title}
      </Text>
      <Box width="fit-content" backgroundColor="secondary-03" borderRadius={16} px={6} my={8}>
        <Text color="white-01" text="app-14-medium">
          {badge}
        </Text>
      </Box>
      <Text color="white-01" text="app-14-medium" opacity={0.6} marginBottom={margin || (button ? 16 : 36)}>
        {description}
      </Text>
      {button && onButtonClick && (
        <Button
          size="tiny"
          className={styles['reward-button']}
          variant={buttonType === 'info' ? 'secondary' : 'primary'}
          onClick={onButtonClick}
        >
          {button}
        </Button>
      )}
    </Box>
  </Box>
);
