import { Event, track } from '@rehold-io/data-layer-client';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useGetNotificationCountQuery } from 'features/Notifications/model/useGetNotificationCountQuery';

import { BellIcon } from 'shared/assets';
import { useToggle } from 'shared/hooks';
import { Box } from 'shared/ui';

import { NotificationCenter } from '../NotificationCenter';

export const Notifications = observer(() => {
  const location = useLocation();
  const [isOpen, toggle, setOpen] = useToggle();
  const { data } = useGetNotificationCountQuery({});
  const count = useRef<number>();

  if (data?.unread !== undefined) {
    count.current = data.unread;
  }

  const handleOpen = () => {
    track(Event.MENU_CLICKED, { from: location.pathname, type: 'notifications' });
    toggle();
  };

  return (
    <>
      <Box
        justifyContent="center"
        alignItems="center"
        minWidth={40}
        height={40}
        ml={8}
        borderRadius={40}
        bg="background-01"
        borderColor="secondary-02"
        sx={{ borderWidth: 1 }}
        onClick={handleOpen}
        color="white-01"
      >
        {!!count.current && (
          <Box
            width={10}
            height={10}
            backgroundColor="black-01"
            position="absolute"
            top={8}
            right={8}
            borderRadius="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Box width={8} height={8} backgroundColor="red-01" borderRadius="100%" />
          </Box>
        )}
        <BellIcon width={24} height={24} />
      </Box>
      <NotificationCenter isOpen={isOpen} onClose={() => setOpen(false)} />
    </>
  );
});
