import cx from 'classnames';
import { FC, FunctionComponent, SVGProps } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTour } from 'features/OnboardingTour';

import { AirdropIcon, DualIcon, MoneyIcon, SwapRoundIcon, TradeIcon } from 'shared/assets';
import { useIsMobile } from 'shared/hooks';
import { PathPatterns } from 'shared/lib';
import { Box, Text } from 'shared/ui';

import styles from './NavTabs.module.scss';

export const NavTabs: FC = () => {
  const { t } = useTranslation();
  const { tour } = useTour();

  const isMobile = useIsMobile();

  return (
    <>
      {isMobile ? (
        ReactDOM.createPortal(
          <Box className={styles['nav-tabs-mobile']}>
            <Box
              flexDirection="row"
              alignItems="center"
              justifyContent="space-around"
              width="100%"
              backgroundColor="black-01"
              boxShadow="0 0 32px 0 rgba(0,0,0,0.6)"
              borderTop="1px solid #20123F"
            >
              <NavTab Icon={TradeIcon} name={t('navigation.perpetual')} index path={PathPatterns.Perpetuals} />
              <NavTab Icon={DualIcon} name={t('navigation.dual')} path={PathPatterns.DualsDashboard} />
              <NavTab
                Icon={SwapRoundIcon}
                name={t('navigation.swap')}
                path={PathPatterns.Swap}
                onClick={() => {
                  tour?.cancel();
                }}
              />
              <NavTab
                Icon={MoneyIcon}
                name={t('navigation.buy')}
                path={PathPatterns.Buy}
                onClick={() => {
                  tour?.cancel();
                }}
              />
              <NavTab
                Icon={AirdropIcon}
                name={t('navigation.points')}
                path={PathPatterns.Points}
                onClick={() => {
                  tour?.cancel();
                }}
              />
            </Box>
          </Box>,
          document.body,
        )
      ) : (
        <Box className={styles['nav-tabs']}>
          <NavTab Icon={TradeIcon} name={t('navigation.perpetual')} index path={PathPatterns.Perpetuals} />
          <NavTab Icon={DualIcon} name={t('navigation.dual')} path={PathPatterns.DualsDashboard} />
          <NavTab
            Icon={SwapRoundIcon}
            name={t('navigation.swap')}
            path={PathPatterns.Swap}
            onClick={() => {
              tour?.cancel();
            }}
          />
          <NavTab
            Icon={MoneyIcon}
            name={t('navigation.buy')}
            path={PathPatterns.Buy}
            onClick={() => {
              tour?.cancel();
            }}
          />
        </Box>
      )}
    </>
  );
};

interface NavTabProps {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  index?: boolean;
  name: string;
  onClick?: () => void;
  path: string;
}

const NavTab: FC<NavTabProps> = ({ name, path, index = false, Icon, onClick }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isActive = (index && pathname === path) || (!index && pathname.includes(path));

  const isMobile = useIsMobile();

  return (
    <Box
      className={cx(styles['nav-tab-item'], { [styles.active]: isActive })}
      onClick={() => {
        if (onClick) onClick();
        navigate(path);
      }}
    >
      {isMobile && <Icon width={24} height={24} color={isActive ? '#05F283' : '#864DF7'} />}
      <Text
        text={!isMobile ? 'app-16-medium' : 'app-10-medium'}
        color={isActive ? 'primary-01' : isMobile ? 'secondary-03' : 'gray-01'}
      >
        {name}
      </Text>
    </Box>
  );
};
