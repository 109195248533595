/* eslint-disable no-multi-spaces */

export const stableOrder = ['usdt', 'fusdt', 'usdc', 'usdbc', 'busd', 'dai', 'dai.e', 'frax', 'eurc'];

export const cryptoOrder = [
  'btc', // 1
  'wbtc', // 1
  'btcb', // 1
  'btc.b', // 1
  'rbtc',
  'wrbtc',
  'eth', // 2
  'weth', // 2
  'weth.e', // 2
  'bnb', // 4
  'wbnb', // 4
  'xrp', // 6
  'ada', // 7
  'doge', // 9
  'avax', // 17
  'wavax', // 17
  'pol',
  'wpol',
  'matic', // 8
  'wmatic', // 8
  'shib', // 13
  'sol', // 11
  'ldo', // 33
  'near', // 34
  'arb', // 39
  'ftm', // 43
  'wftm', // 43
  'dot', // 12
  'ltc', // 15
  'trx', // 14
  'uni', // 18
  'atom', // 20
  'link', // 21
  'bch', // 27
  'fil', // 28
  'xlm', // 30
  'vet', // 36
  'ape', // 38
  'qnt', // 40
  'grt', // 43
  'eos', // 45
  'axs', // 46
  'mana', // 48
  'aave', // 49
  'imx', // 52
  'sand', // 54
  'rndr', // 61
  'snx', // 64
  'crv', // 68
  'op', // 69
  'mkr', // 69
  'gmx', // 71
  'chz', // 73
  'fxs', // 74
  'gala', // 75
  'pepe', // 76
  'cfx', // 84
  'zil', // 88
  'twt', // 89
  'dydx', // 90
  '1inch', // 91
  'cake', // 102
  'fet', // 103
  'sfp', // 110
  'floki', // 113
  'comp', // 113
  'inj', // 114
  'magic', // 115
  'bal', // 117
  'mask', // 120
  'lpt', // 124
  'sushi', // 133
  'wld', // 138
  'gmt', // 147
  'meme', // 151
  'stg', // 176
  'knc', // 196
  'pla', // 241
  'chr', // 251
  'rdnt', // 251
  'c98', // 260
  'id', // 274
  'arkm', // 301
  'pond', // 315
  'joe', // 320
  'spell', // 328
  'ghst', // 382
  'rif', // 392
  'hook', // 408
  'alpaca', // 423
  'quick', // 429
  'bifi', // 461
  'bake', // 479
  'qi', // 509
  'boo', // 695
  'mim', // 2862
  'xava', // 3104
];

// NOTE: .reverse() required to place unknown tickers at the bottom of the list
export const totalOrder = [...stableOrder, ...cryptoOrder].reverse();

export const tokenOrder = new Map(totalOrder.map((token, index) => [token, index]));
