import { useMediaQuery } from '@mui/material';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useState } from 'react';

import { useGetDomain } from 'entities/Domain';

import { WalletIcon } from 'shared/assets';
import { useAccount } from 'shared/hooks';
import { shortenIfAddress } from 'shared/lib';
import { Box, Menu, Text } from 'shared/ui';

import { MenuContext, MenuType } from '../SharedMenu';

import { WalletMenu } from './menu';
import styles from './styles.module.scss';

export const NavWallet = observer(() => {
  const { address, isConnected } = useAccount();
  const { menu, setMenu } = useContext(MenuContext);

  const [isOpen, toggle] = useState<boolean>(false);

  const { name: domain } = useGetDomain();
  const slice = 20;

  const viewAddress = domain || address || '';

  const isSmallDesktop = useMediaQuery('(max-width: 864px) and (min-width: 768px)', { noSsr: true });
  const isMobile = useMediaQuery('(max-width: 575px)', { noSsr: true });
  const isSmallMobile = useMediaQuery('(max-width: 420px)', { noSsr: true });

  const handleToggle = useCallback(() => {
    if (!isMobile) toggle((isOpen) => !isOpen);
    if (isMobile) setMenu(menu === MenuType.WALLET ? null : MenuType.WALLET);
  }, [toggle, setMenu, menu, isMobile]);

  const trigger = (
    <Box
      className={cx('menu-header-trigger', styles.trigger, {
        [styles.open]: isMobile ? menu === MenuType.WALLET : isOpen,
      })}
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      minWidth={40}
      height={40}
      px={{ default: 0, phone: 10 }}
      ml={8}
      borderRadius={40}
      sx={{ borderWidth: 1 }}
      onClick={handleToggle}
      color="white-01"
    >
      <WalletIcon width={24} height={24} />
      {!isSmallDesktop && <Text ml={8}>{shortenIfAddress(viewAddress, domain ? slice : 4)}</Text>}
    </Box>
  );

  if (!isConnected || isSmallMobile) return;

  return (
    <>
      {isMobile && trigger}
      {!isMobile && (
        <Menu
          trigger={trigger}
          isOpen={isOpen}
          toggle={toggle}
          blockBackgroundScroll
          py={0}
          px={0}
          minWidth={220}
          hoverable={false}
          borderColor="secondary-02"
          menuGap={22}
          showCorner={false}
        >
          <Box>
            <WalletMenu onToggle={toggle} />
          </Box>
        </Menu>
      )}
    </>
  );
});
