import { useMediaQuery } from '@mui/material';
import cx from 'classnames';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { MenuIcon } from 'shared/assets';
import { useNetwork } from 'shared/hooks/network';
import { Box } from 'shared/ui';
import { Menu } from 'shared/ui/Menu';

import { MenuContext, MenuType } from '../SharedMenu';

import { MainMenu } from './Menu';
import styles from './styles.module.scss';
import { Page } from './types';

export const NavMain = () => {
  const { network } = useNetwork();
  const { menu, setMenu } = useContext(MenuContext);

  const [isOpen, toggle] = useState<boolean>(false);
  const [page, setPage] = useState<Page>('root');

  const isMobile = useMediaQuery('(max-width: 575px)', { noSsr: true });

  const handleToggle = useCallback(() => {
    if (!isMobile) toggle((isOpen) => !isOpen);
    if (isMobile) setMenu(menu === MenuType.MAIN ? null : MenuType.MAIN);
  }, [toggle, setMenu, menu, isMobile]);

  useEffect(() => {
    if (!isOpen) setPage('root');
  }, [isOpen]);

  const trigger = (
    <Box
      className={cx('menu-header-trigger', styles.trigger, {
        [styles.open]: isMobile ? menu === MenuType.MAIN : isOpen,
      })}
      onClick={handleToggle}
    >
      {network?.renderLogo(24)}
      <Box mx={8} height={24} width="1px" backgroundColor="secondary-02" />
      <MenuIcon width={24} height={24} />
    </Box>
  );

  return (
    <>
      {isMobile && trigger}
      {!isMobile && (
        <Menu
          trigger={trigger}
          isOpen={isOpen}
          toggle={toggle}
          blockBackgroundScroll
          px={0}
          py={0}
          hoverable={false}
          borderColor="secondary-02"
          menuGap={22}
          showCorner={false}
        >
          <MainMenu toggle={toggle} page={page} onChangePage={setPage} />
        </Menu>
      )}
    </>
  );
};
