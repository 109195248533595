import { useMediaQuery } from '@mui/material';
import { Event, track } from '@rehold-io/data-layer-client';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Address, isAddress } from 'viem';
import { useToken } from 'wagmi';

import { getFormattedEthers } from 'widgets/Swap/lib/getFormattedBalance';
import { usePinnedTokens } from 'widgets/Swap/lib/usePinnedTokens';

import { useSwapForm, useSwapTokenByAddress, useSwapTokens } from 'entities/Swap/lib/hooks';
import { SwapTokenIcon, getTokenByAddress } from 'entities/Token';
import { TokenFullData, UserToken } from 'entities/Token/model/types';

import { useNetwork } from 'shared/hooks/network';
import { Box, Button, Modal, PageContent, Text } from 'shared/ui';

import { SwapAsset } from '../SwapAsset';

import styles from './SwapAssetSelect.module.scss';
import { SwapAssetSelectModalFilter } from './SwapAssetSelectModalFilter';

interface SwapAssetSelectModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (selected: Address) => void;
  selected: Address | null;
}

export const SwapAssetSelectModal: FC<SwapAssetSelectModalProps> = observer(
  ({ selected, isOpen, onClose, onSelect }) => {
    const { t } = useTranslation();
    const { formValues } = useSwapForm();
    const { selectedChainId: chainId } = useNetwork();

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { tokens, isLoading: isTokensLoading } = useSwapTokens();
    const pinnedTokens = usePinnedTokens();

    const [filteredTokens, setFilteredTokens] = useState<UserToken[]>([]);

    const [searchText, setSearchText] = useState('');
    const [debouncedText, setDebouncedText] = useState('');

    const [addressToken, setAddressToken] = useState<Address>();

    const { data: singleTokenData, isLoading: isSingleTokenLoading } = useToken({
      chainId,
      address: addressToken,
      enabled: !!addressToken,
    });

    useEffect(() => {
      if (!isTokensLoading && tokens && tokens.length !== 0) {
        const filter = async () => {
          if (!debouncedText) {
            return tokens;
          }

          if (isAddress(debouncedText.toLowerCase())) {
            setAddressToken(debouncedText.toLowerCase() as Address);
          }

          if (addressToken && singleTokenData && isAddress(debouncedText.toLowerCase())) {
            return [
              {
                address: singleTokenData.address,
                decimals: singleTokenData.decimals,
                name: singleTokenData.name,
                symbol: singleTokenData.symbol,
                logoURI: '',
              } as UserToken,
            ];
          }

          return tokens.filter((token) => {
            const text = debouncedText.toLowerCase();
            const escapedInput = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const inputRegexp = new RegExp(`[a-zA-Z_]*${escapedInput}[a-zA-Z_]*`, 'i');

            const isMatchSearchText = text ? inputRegexp.test(`${token.symbol}${token.name}`) : true;

            return isMatchSearchText;
          });
        };

        const sort = (tokens: UserToken[]) =>
          tokens.slice().sort((a, b) => {
            const isFirstZero = a.balance === null || getFormattedEthers(a) === '0';
            const isSecondZero = b.balance === null || getFormattedEthers(b) === '0';

            if (isFirstZero && isSecondZero) {
              return 0;
            }
            if (isFirstZero) {
              return 1; // Move tokens with null balances to the end
            }
            if (isSecondZero) {
              return -1; // Move tokens with null balances to the end
            }

            return 0;
          });

        const unique = (arr: UserToken[]) => {
          const seen = new Set();
          return arr.filter((item) => {
            const value = item.address;
            if (seen.has(value)) {
              return false; // Filter out duplicates
            }
            seen.add(value);
            return true; // Keep the first occurrence
          });
        };

        filter().then((tokens) => setFilteredTokens(unique(sort(tokens))));
      }
    }, [tokens, isTokensLoading, singleTokenData, debouncedText]);

    useEffect(() => {
      if (!isOpen) {
        setSearchText('');
        setDebouncedText('');
      }
    }, [isOpen]);

    const handleSelect = (selected: Address, symbol: string, pinned: boolean) => {
      onSelect(selected);
      track(Event.SWAP_TOKENS_SELECTED, { pinned, ticker: symbol });
    };

    const handleClose = () => {
      onClose();
    };

    const isWideMobile = useMediaQuery('(max-width: 575px)', { noSsr: true });

    return (
      <Modal
        className={styles.modal}
        isOpen={isOpen}
        onClose={handleClose}
        maxWidth={isWideMobile ? '100%' : 500}
        stickyTop
        closeIcon
        header={
          <Box height={24} px={22}>
            <Text text="app-18-medium" style={{ position: 'absolute', bottom: 0 }}>
              {t('modals.swapTokenSelection.title')}
            </Text>
          </Box>
        }
      >
        <PageContent height="100%" maxHeight="100%" flexShrink={2} px={22}>
          <SwapAssetSelectModalFilter
            onClose={handleClose}
            onSearch={setSearchText}
            setDebouncedText={setDebouncedText}
            searchText={searchText}
          />
          <Box flexDirection="row" mb={12} mt={2}>
            <div className={`${styles.tickers} hide-scroll`}>
              {pinnedTokens.map(({ symbol, address }) => (
                <PinnedToken
                  key={`pinned-token-${address}`}
                  address={address as Address}
                  onClick={() => handleSelect(address, symbol, true)}
                  selected={selected === address}
                />
              ))}
            </div>
          </Box>
          {!isTokensLoading && !isSingleTokenLoading && (
            <ul className={`${styles.assets} hide-scroll`}>
              {filteredTokens.map((token) => {
                const tokenFromStorage = getTokenByAddress<TokenFullData>(tokens, token.address!);
                return (
                  <SwapAsset
                    key={`swap-asset-index-${token.address}`}
                    address={token.address}
                    token={{
                      ...token,
                      logoURI: tokenFromStorage?.logoURI || '',
                    }}
                    onPress={(selected) => handleSelect(selected, token.symbol, false)}
                    className={styles.item}
                    selected={formValues.fromToken === token.address || formValues.toToken === token.address}
                  />
                );
              })}
              {!filteredTokens.length && (
                <div className={styles.noResults}>
                  <Text text="app-16-medium" sx={{ opacity: 0.4 }}>
                    {t('common.noResults')}
                  </Text>
                </div>
              )}
            </ul>
          )}
        </PageContent>
      </Modal>
    );
  },
);

interface PinnedTokenProps {
  address: Address;
  onClick: () => void;
  selected: boolean;
}

const PinnedToken: FC<PinnedTokenProps> = ({ address, onClick, selected }) => {
  const { data } = useSwapTokenByAddress(address);

  return (
    <>
      {data && (
        <Button
          key={address}
          size="tiny"
          width="fit-content"
          variant={`secondary${!selected ? '-dark' : ''}`}
          borderRadius={24}
          pl={8}
          pr={16}
          my={4}
          mr={8}
          onClick={onClick}
          text="app-14-medium"
          data-id={`select-token-${address}`}
        >
          <Box flexDirection="row" alignItems="center" gap={6}>
            {data && <SwapTokenIcon address={address} url={data.logoURI} size={20} />}
            {data && data.symbol}
          </Box>
        </Button>
      )}
    </>
  );
};
