import { useMediaQuery } from '@mui/material';
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { hideStep } from 'features/OnboardingTour';

import { CheckIcon } from 'shared/assets';
import { AppChain } from 'shared/config/chains/types';
import { Box, Text, Spinner } from 'shared/ui';

import styles from './styles.module.scss';

interface NetworkItemProps {
  isNew?: boolean;
  network: AppChain<any>;
  onNetworkChange: (nextNetworkId: number) => void;
  selected?: boolean;
  switching?: boolean;
  toggle: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NetworkItem: React.FC<NetworkItemProps> = React.memo(
  ({ network, onNetworkChange, selected, switching, toggle }) => {
    const { disabled, id, name, status } = network;
    const { t } = useTranslation();

    const handlePress = useCallback(() => {
      onNetworkChange(id);
      toggle(false);
      hideStep();
    }, [id, onNetworkChange, toggle]);

    const disabledStyles = useMemo(() => {
      if (disabled) {
        return {
          opacity: 0.4,
        };
      }

      return {};
    }, [disabled]);

    const isMobile = useMediaQuery('(max-width: 575px)', { noSsr: true });

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={handlePress}
        data-id="newtwork-switch"
        disabled={disabled}
        className={isMobile ? undefined : styles['menu-item']}
        height={isMobile ? 64 : 48}
        paddingX={isMobile ? 24 : 24}
        paddingY={isMobile ? 20 : 24}
        backgroundColor={isMobile && selected ? 'secondary-02' : undefined}
        borderBottom={isMobile ? '1px solid' : undefined}
        borderColor={isMobile ? 'secondary-02' : undefined}
      >
        <Box flexDirection="row" alignItems="center">
          <Box position="relative" mr={isMobile ? 16 : 8} {...disabledStyles}>
            {network.renderLogo(24)}
            {switching && (
              <Box
                position="absolute"
                top={0}
                right={0}
                bottom={0}
                left={0}
                justifyContent="center"
                alignItems="center"
              >
                <Spinner size={24} />
              </Box>
            )}
          </Box>
          <Text text="app-14-medium" color={selected ? 'primary-01' : 'white-01'} {...disabledStyles}>
            {name}
          </Text>
          {status && (
            <Text
              text="app-12-regular"
              color="black-01"
              sx={{ opacity: 1 }}
              ml={14}
              bgColor="primary-01"
              px={6}
              borderRadius={20}
            >
              {t(`common.${status}`)}
            </Text>
          )}
          {disabled && (
            <Text
              text="app-12-regular"
              color="black-01"
              sx={{ opacity: 1 }}
              ml={14}
              bgColor="primary-01"
              px={6}
              borderRadius={20}
            >
              {t('common.soon')}
            </Text>
          )}
        </Box>
        {selected && (
          <Box color="primary-01">
            <CheckIcon width={isMobile ? 24 : 20} height={isMobile ? 24 : 20} />
          </Box>
        )}
      </Box>
    );
  },
);
