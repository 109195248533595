import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useEffect, useState } from 'react';

import { useAccount } from 'shared/hooks';
import { Box, Menu } from 'shared/ui';

import { MainMenuMobile } from '../NavMain/Menu';
import { WalletPage } from '../NavMain/Menu/WalletPage';
import { Page } from '../NavMain/types';

import { MenuType, MenuContext } from './context';

export { MenuContext, MenuContextProvider, MenuType } from './context';

export const SharedMenu = observer(() => {
  const { isConnected } = useAccount();
  const { menu, setMenu } = useContext(MenuContext);

  const [page, setPage] = useState<Page>('root');

  const isMobile = useMediaQuery('(max-width: 575px)', { noSsr: true });
  const isSmallMobile = useMediaQuery('(max-width: 420px)', { noSsr: true });

  const handleToggle = useCallback(
    (menu: MenuType | null, value: boolean) => {
      if (value) setMenu(menu);
      if (!value) setMenu(null);
    },
    [menu, setMenu],
  );

  useEffect(() => {
    if (!menu) setPage('root');
  }, [menu]);

  useEffect(() => {
    if (menu === MenuType.WALLET && isSmallMobile) {
      setPage('wallet');
      setMenu(MenuType.MAIN);
    }
  }, [isSmallMobile]);

  useEffect(() => {
    const handlers = [
      ...document.getElementsByClassName('menu-header-trigger'),
      ...document.getElementsByClassName('menu-header-notifications'),
    ];

    const handleClickOutside: (...args: any) => void = ({ target }) => {
      if (!target) {
        setMenu(null);
        return;
      }

      const isHandlerTouched = handlers.some((handler) => handler.contains(target));

      if (!isHandlerTouched) {
        setMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSmallMobile, isMobile]);

  if (!isMobile) return;

  return (
    <Menu
      trigger={<></>}
      isOpen={!!menu}
      toggle={() => {}}
      blockBackgroundScroll
      py={0}
      px={0}
      minWidth={220}
      hoverable={false}
      borderColor="transparent"
      showCorner={false}
    >
      <Box height="calc(100dvh - 67px)" className="menu-header-trigger">
        {menu === MenuType.MAIN && (
          <MainMenuMobile toggle={(value) => handleToggle(MenuType.MAIN, !!value)} page={page} onChangePage={setPage} />
        )}
        {menu === MenuType.WALLET && !isSmallMobile && isConnected && (
          <WalletPage toggle={(value) => handleToggle(MenuType.WALLET, !!value)} />
        )}
      </Box>
    </Menu>
  );
});
