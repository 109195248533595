import { Event, track } from '@rehold-io/data-layer-client';
import React, { FC, memo, useCallback, useState } from 'react';

import { OnrampCryptoCurrency } from 'entities/Onramp/model';

import { OnrampAssetSelectButton } from './OnrampAssetSelectButton';
import { OnrampAssetSelectModal } from './OnrampAssetSelectModal';

interface Props {
  onSelect: (value: OnrampCryptoCurrency | null) => void;
  value: OnrampCryptoCurrency | null;
}

export const OnrampAssetSelect: FC<Props> = memo(({ value, onSelect }) => {
  const [isOpen, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    track(Event.ONRAMP_TOKENS_CLICKED);
    setOpen((open) => !open);
  }, [setOpen]);

  const handleSelect = useCallback(
    (currency: OnrampCryptoCurrency) => {
      onSelect(currency);
      setOpen(false);
    },
    [onSelect, setOpen],
  );

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  return (
    <>
      <OnrampAssetSelectButton onClick={handleClick} selected={value} />
      <OnrampAssetSelectModal selected={value} isOpen={isOpen} onClose={handleClose} onSelect={handleSelect} />
    </>
  );
});
