import { useEffect, useState } from 'react';

const POLLING_INTERVAL = 200;

export const useLiveChatWidget = () => {
  const [widget, setWidget] = useState<any>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.LiveChatWidget) {
        setWidget(window.LiveChatWidget);
        clearInterval(interval);
      }
    }, POLLING_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  return widget || window.LiveChatWidget;
};
