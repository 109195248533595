import { useCallback, useEffect, useState } from 'react';

import { useAccount } from 'shared/hooks';

import { useLiveChatWidget } from './useLiveChatWidget';

const LIVECHAT_ANIMATION_DURATION_MS = 200;

export const useLiveChatSettings = () => {
  const { address } = useAccount();
  const [ready, setReady] = useState(false);

  const widget = useLiveChatWidget();
  const widgetEelement = document.getElementById('chat-widget-container');

  const onVisibilityChanged = useCallback(
    (data: any) => {
      if (data.visibility === 'minimized') {
        setTimeout(() => widget?.call('hide'), LIVECHAT_ANIMATION_DURATION_MS);
      }
      if ((data.visibility === 'maximized' || data.visibility === 'hidden') && widgetEelement) {
        widgetEelement.style.display = 'initial';
      }
    },
    [widgetEelement, ready],
  );

  const onChatEvent = useCallback(() => {
    widget?.call('maximize');
  }, [widget]);

  useEffect(() => {
    if (widget) {
      widget.init();
      widget.on('ready', () => setReady(true));
      widget.on('new_event', onChatEvent);
    }
  }, [widget]);

  useEffect(() => {
    if (widget) {
      widget.on('visibility_changed', onVisibilityChanged);
      widget.call('hide');
    }

    if (ready && widgetEelement) {
      widgetEelement.style.display = 'none';
    }
    return () => {
      if (widget) {
        widget.off('visibility_changed', onVisibilityChanged);
      }
    };
  }, [widget, widgetEelement, ready, onVisibilityChanged]);

  useEffect(() => {
    if (widget && ready) {
      widget?.call('set_customer_name', address || null);
    }
  }, [address, ready, widget]);
};
