import { useState, useEffect, useCallback, useRef } from 'react';

import { DualAssetType } from 'entities/Dual';

import { DualsListAsset } from './DualsTableAsset';

const ITEM_HEIGHT = 58;
const PRERENDER_COUNT = 2;

export const LazyDualsList = (props: { assets: DualAssetType[]; onSelect: (item: DualAssetType) => void }) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [scrollY, setScrollY] = useState(window.scrollY);

  const heightOffset = useRef<number | null>(null);

  useEffect(() => {
    const element = document.getElementById('duals-list');
    if (element) {
      const offsetTop = element.getBoundingClientRect().top;
      heightOffset.current = offsetTop + window.scrollY;
    }
  }, []);

  const isScrollingRef = useRef(false);

  const getElementTopOffset = useCallback((index: number) => (heightOffset.current || 0) + index * ITEM_HEIGHT, []);
  const getElementBottomOffset = useCallback(
    (index: number) => (heightOffset.current || 0) + (index + 1) * ITEM_HEIGHT,
    [],
  );

  const isShown = useCallback(
    (index: number) => {
      const startRenderOffset = scrollY - PRERENDER_COUNT * ITEM_HEIGHT;
      const endRenderOffset = scrollY + windowHeight + PRERENDER_COUNT * ITEM_HEIGHT;

      return getElementBottomOffset(index) >= startRenderOffset && getElementTopOffset(index) <= endRenderOffset;
    },
    [scrollY, windowHeight, getElementBottomOffset, getElementTopOffset],
  );

  const handleResize = useCallback(() => {
    setWindowHeight(window.innerHeight);
  }, []);

  const handleScroll = useCallback(() => {
    if (isScrollingRef.current) {
      return;
    }

    isScrollingRef.current = true;
    requestAnimationFrame(() => {
      setScrollY(window.scrollY);
      isScrollingRef.current = false;
    });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, handleResize]);

  return (
    <div
      id="duals-list"
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: props.assets.length * ITEM_HEIGHT,
      }}
    >
      {props.assets.map(
        (asset, index) =>
          isShown(index) && (
            <DualsListAsset
              style={{
                position: 'absolute',
                top: index * ITEM_HEIGHT,
                height: ITEM_HEIGHT,
                width: '100%',
              }}
              key={`dual-item-${asset.baseTicker}-${asset.quoteTicker}-${asset.chainId}`}
              dual={asset}
              onPress={() => props.onSelect(asset)}
            />
          ),
      )}
    </div>
  );
};
