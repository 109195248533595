import { Event, track } from '@rehold-io/data-layer-client';
import { observer } from 'mobx-react-lite';
import { useDeferredValue, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCalculateOutput } from 'widgets/Dual/lib/useCalculateOutput';
import { DashboardFormValues } from 'widgets/Dual/model/form';

import { DualChart } from 'entities/Dual/ui';
import { useRate } from 'entities/Rates';

import { HintIcon } from 'shared/assets';
import { Box, ButtonIcon, Spinner } from 'shared/ui';

import { HowItWorks } from '../HowItWorks';

export const DualChartPreview = observer(() => {
  const { t } = useTranslation();
  const [isOpenHowItWorks, setIsOpenHowItWorks] = useState(false);

  const { control } = useFormContext<DashboardFormValues>();
  const [inputAmountField, inputTickerField, currentTariff, chainId] = useWatch({
    control,
    name: ['inputAmount', 'inputTicker', 'tariff', 'chainId'],
  });

  const inputTicker = inputTickerField;

  const defferedInputAmount = useDeferredValue(inputAmountField || 0);

  const baseTicker = currentTariff?.baseTicker || '';
  const quoteTicker = currentTariff?.quoteTicker || '';

  const rateObj = useRate({ from: baseTicker, to: quoteTicker, inputTicker });

  const { output } = useCalculateOutput();

  const handleOpenHowItWorks = () => {
    setIsOpenHowItWorks(true);
    track(Event.DUAL_HOW_IT_WORKS_CLICKED);
  };

  if (!output || !rateObj?.ready || !currentTariff) {
    return (
      <Box height={208} justifyContent="center" alignItems="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      <DualChart
        baseTicker={baseTicker}
        quoteTicker={quoteTicker}
        entryPrice={rateObj.formatted.price}
        stakingPeriod={t('common.hoursWithCount', {
          count: currentTariff?.stakingPeriod,
        })}
        calculation={output}
        chainId={chainId}
      >
        <Box position="absolute" top="8px" right="8px">
          <ButtonIcon
            isOutlined
            sx={{ padding: '4px' }}
            data-id="hint-chart-how-it-works"
            onClick={handleOpenHowItWorks}
          >
            <HintIcon width={16} height={16} />
          </ButtonIcon>
        </Box>
      </DualChart>

      <HowItWorks
        inputAmount={Number(defferedInputAmount)}
        inputTicker={inputTicker}
        baseTicker={baseTicker}
        quoteTicker={quoteTicker}
        apr={currentTariff.apr}
        calculation={output}
        stakingPeriod={currentTariff.stakingPeriod}
        isOpenHowItWorks={isOpenHowItWorks}
        setIsOpenHowItWorks={setIsOpenHowItWorks}
      />
    </>
  );
});
