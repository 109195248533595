import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { supportedChains } from 'shared/config/chains';
import { useNetwork, useSwitchNetwork } from 'shared/hooks/network';
import { Box, Text } from 'shared/ui';

import { MenuProps } from '../../types';
import { PageHeader } from '../PageHeader';

import { NetworkItem } from './item';

export const NetworkPage: FC<MenuProps> = observer(({ onPressBack, toggle }) => {
  const { switchNetwork } = useSwitchNetwork();
  const { chainId: currentNetworkId, switchingTo } = useNetwork();
  const { t } = useTranslation();

  const isMobile = useMediaQuery('(max-width: 575px)', { noSsr: true });

  if (isMobile)
    return (
      <>
        {onPressBack && <PageHeader onPressBack={onPressBack}>{t('menu.main.networks')}</PageHeader>}
        <Box overflow="auto" flexBasis="0px" flexGrow="1">
          {supportedChains.map((network) => {
            const { id } = network;
            return (
              <NetworkItem
                key={id}
                network={network}
                onNetworkChange={switchNetwork}
                selected={id === currentNetworkId}
                switching={id === switchingTo}
                toggle={toggle}
              />
            );
          })}
        </Box>
      </>
    );

  return (
    <Box pb={16}>
      <Box justifyContent="center" paddingX={24} paddingY={20}>
        <Text text="app-14-medium" color="secondary-01">
          {t('common.network')}
        </Text>
      </Box>
      <Box>
        {supportedChains.map((network) => {
          const { id } = network;
          return (
            <NetworkItem
              key={id}
              network={network}
              onNetworkChange={switchNetwork}
              selected={id === currentNetworkId}
              switching={id === switchingTo}
              toggle={toggle}
            />
          );
        })}
      </Box>
    </Box>
  );
});
