/* eslint-disable jsx-a11y/control-has-associated-label */
import useResizeObserver from '@react-hook/resize-observer';
import classnames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DualAssetType } from 'entities/Dual';

import { Box, Spinner, Text } from 'shared/ui';

import styles from './DualsTable.module.scss';
import { LazyDualsList } from './LazyDualsTable';

interface Props {
  assets?: DualAssetType[];
  onSelect: (dual: DualAssetType) => void;
}

export const DualsList: React.FC<Props> = ({ assets, onSelect }) => {
  const { t } = useTranslation();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useResizeObserver(document.body, (resize) => setScreenWidth(resize.borderBoxSize[0]?.inlineSize));

  const aprWidth = screenWidth <= 390 ? '92px' : '118px';
  const aprWidthSmall = screenWidth <= 340 ? '58px' : aprWidth;

  return (
    <>
      <Box className={classnames(styles['list-item'], styles['item-header'])}>
        <Box width="50%" className={classnames(styles['list-head-cell'], styles['list-column-big'])}>
          <Text text="app-12-regular" color="gray-01">
            {t('main.table.pair')}
          </Text>
        </Box>
        <th className={classnames(styles['list-head-cell'])}>
          <Text text="app-12-regular" color="gray-01">
            {t('main.table.chain')}
          </Text>
        </th>
        <th style={{ width: aprWidthSmall }} className={classnames(styles['list-head-cell'])}>
          <Box width={aprWidthSmall} alignItems="center">
            <Text text="app-12-regular" color="gray-01">
              {t('main.table.apr')}
            </Text>
          </Box>
        </th>
      </Box>
      <Box marginX="16px">
        <Box height="1px" backgroundColor="secondary-02" />
      </Box>
      <LazyDualsList assets={assets || []} onSelect={onSelect} />
      {!assets && (
        <div className={styles.noResults}>
          <Spinner />
        </div>
      )}
      {assets && assets.length === 0 && (
        <div className={styles.noResults}>
          <Text text="app-16-medium" sx={{ opacity: 0.4 }}>
            {t('common.noResults')}
          </Text>
        </div>
      )}
    </>
  );
};
