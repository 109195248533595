import { CurrencyIcon } from 'shared/ui/CurrencyIcon';

import { routerAbi } from './abi';
import { AppChain } from './types';

export const polygon: AppChain<'polygon'> = {
  blockExplorers: {
    default: {
      name: 'PolygonScan',
      url: 'https://polygonscan.com',
    },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  disabled: false,
  domain: {
    address: '0xa9a6A3626993D487d2Dbda3173cf58cA1a9D9e9f',
  },
  id: 137,
  logo: <CurrencyIcon ticker="pol" size={24} />,
  name: 'Polygon',
  nativeCurrency: {
    decimals: 18,
    name: 'POL',
    symbol: 'POL',
  },
  network: 'polygon',
  tokenStandard: 'ERC-20',
  renderLogo: (size) => <CurrencyIcon ticker="pol" size={size} />,
  router: {
    abi: routerAbi,
    address: '0xa04295018eb7353a9755a2b227c9203fed3d9b7c',
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.ankr.com/polygon'],
    },
    public: {
      http: ['https://rpc.ankr.com/polygon'],
    },
  },
  vault: {
    address: '0xde6b4964c4384bcdfa150a4a8be9865c5b91e29c',
  },
};
