import React, { CFC, useMemo, useState } from 'react';

export enum MenuType {
  MAIN = 'main',
  WALLET = 'wallet',
}

type ContextValue = {
  menu: MenuType | null;
  setMenu: (value: MenuType | null) => void;
};

export const MenuContext = React.createContext({} as ContextValue);

export const MenuContextProvider: CFC = ({ children }) => {
  const [menu, setMenu] = useState<MenuType | null>(null);

  const state = useMemo(
    () => ({
      menu,
      setMenu,
    }),
    [menu, setMenu],
  );

  return <MenuContext.Provider value={state}>{children}</MenuContext.Provider>;
};
