import { Event, track } from '@rehold-io/data-layer-client';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDisconnect } from 'wagmi';

import { CopyIcon, DisconnectIcon, ExploreIcon } from 'shared/assets';
import { useAccount, useBlockExplorerLink, useCopy } from 'shared/hooks';
import { shortenIfAddress } from 'shared/lib';
import { Box, Text } from 'shared/ui';

import { MenuProps } from '../../types';
import { PageHeader } from '../PageHeader';

export const WalletPage: React.FC<MenuProps> = observer(({ onPressBack, toggle }) => {
  const { t } = useTranslation();

  const { pathname: from } = useLocation();
  const { address, connector } = useAccount();
  const { disconnectAsync } = useDisconnect();

  const copy = useCopy(address);
  const link = useBlockExplorerLink('address', address);

  const handleCopyAddressClick = useCallback(() => {
    if (address) {
      track(Event.WALLET_ADDRESS_COPIED, { address });
      copy();
    }
  }, [address, copy]);

  const handleViewExplorerClick = useCallback(() => {
    track(Event.WALLET_ADDRESS_VIEW_EXPLORER_CLICKED, { address: address! });
    window.open(link?.url, '_blank')?.focus();
  }, [address, link]);

  const handleDisconnectClick = useCallback(async () => {
    await disconnectAsync();

    if (address && connector) track(Event.WALLET_DISCONNECTED, { address, from, provider: connector.name });

    toggle(false);
  }, [address, connector, disconnectAsync, from]);

  return (
    <>
      {onPressBack && (
        <PageHeader onPressBack={onPressBack}>
          {t('menu.wallet.wallet')} {shortenIfAddress(address, 4)}
        </PageHeader>
      )}
      <Box overflow="auto" flexBasis="0px" flexGrow="1">
        <Box
          height={64}
          paddingX={24}
          paddingY={20}
          borderBottom="1px solid"
          borderColor="secondary-02"
          onClick={handleCopyAddressClick}
        >
          <Box flexDirection="row" alignItems="center" gap="8px">
            <CopyIcon width={24} height={24} />
            <Text text="app-14-medium">{t('menu.wallet.copy')}</Text>
          </Box>
        </Box>
        <Box
          height={64}
          paddingX={24}
          paddingY={20}
          borderBottom="1px solid"
          borderColor="secondary-02"
          onClick={handleViewExplorerClick}
        >
          <Box flexDirection="row" alignItems="center" gap="8px">
            <ExploreIcon width={24} height={24} />
            <Text text="app-14-medium">{t('common.viewOnExplorer')}</Text>
          </Box>
        </Box>
        <Box
          height={64}
          paddingX={24}
          paddingY={20}
          borderBottom="1px solid"
          borderColor="secondary-02"
          onClick={handleDisconnectClick}
        >
          <Box flexDirection="row" alignItems="center" gap="8px">
            <DisconnectIcon width={24} height={24} />
            <Text text="app-14-medium">{t('menu.wallet.disconnect')}</Text>
          </Box>
        </Box>
      </Box>
    </>
  );
});
