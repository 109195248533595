import { useMediaQuery } from '@mui/material';
import { Event, track } from '@rehold-io/data-layer-client';
import BigNumber from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OnValueChange } from 'react-number-format';
import { NumberFormatValues } from 'react-number-format/types/types';

import { useSwapSlippage } from 'widgets/Swap/lib/useSwapSlippage';
import { SwapSlippageTypeTab } from 'widgets/Swap/model';

import { SwapInlineWarning } from 'entities/Swap';

import { DEFAULT_SLIPPAGE_VALUE } from 'shared/config';
import { useMutableDebounce } from 'shared/hooks';
import { Menu, Modal, PageContent, Tab, Tabs, Text } from 'shared/ui';
import { Box } from 'shared/ui/Box';

import styles from './SwapSettings.module.scss';
import { SwapSettingsButton } from './SwapSettingsButton';
import { SwapSettingsSlippageInput } from './SwapSettingsSlippageInput';
import { SwapSettingsSlippageTooltip } from './SwapSettingsSlippageTooltip';

export const SLIPPAGE_INPUT_DEBOUNCE = 1200;

interface Props {}

export const SwapSettings: FC<Props> = observer(() => {
  const { t } = useTranslation();

  const [isOpen, toggle] = useState<boolean>(false);
  const [tab, setTab] = useState(SwapSlippageTypeTab.AUTO);

  const { setSlippage: updateSlippage, isHighSlippage } = useSwapSlippage();

  const [slippageText, setSlippageText] = useState('');
  const [debouncedSlippageText, setDebouncedSlippageText] = useMutableDebounce(slippageText, SLIPPAGE_INPUT_DEBOUNCE);

  // Update the slippage form value when the text gets updated
  useEffect(() => {
    updateSlippage(
      !BigNumber(slippageText).isNaN() && BigNumber(slippageText).gt(0)
        ? BigNumber(slippageText).div(100).toString()
        : '',
    );
  }, [slippageText]);

  // Uses debounced slippage text to check if the input is zero
  useEffect(() => {
    if (debouncedSlippageText && BigNumber(debouncedSlippageText).isEqualTo(0)) {
      handleSlippageChange({ value: '' });
      setDebouncedSlippageText('');
    }
  }, [debouncedSlippageText]);

  // Checks if the input is zero and the settings are closed
  useEffect(() => {
    if (!isOpen && slippageText && BigNumber(slippageText).isZero()) {
      handleTabChange(SwapSlippageTypeTab.AUTO);
    }
  }, [isOpen]);

  const handleTabChange = (value: string) => {
    if (value === SwapSlippageTypeTab.CUSTOM) setSlippageText(BigNumber(DEFAULT_SLIPPAGE_VALUE).times(100).toString());
    if (value === SwapSlippageTypeTab.AUTO) setSlippageText('');
    track(Event.SWAP_SETTINGS_SLIPPAGE_TAB_CHANGED, { type: value });
    setTab(value as SwapSlippageTypeTab);
  };

  const handleSlippageChange = ({ value }: Pick<NumberFormatValues, 'value'>) => {
    if (tab === SwapSlippageTypeTab.CUSTOM && (value === '' || BigNumber(value).isZero())) {
      track(Event.SWAP_SETTINGS_SLIPPAGE_TAB_CHANGED, { type: SwapSlippageTypeTab.AUTO });
      setTab(SwapSlippageTypeTab.AUTO);
    }
    if (tab === SwapSlippageTypeTab.AUTO && !BigNumber(value).isNaN() && BigNumber(value).gt(0)) {
      track(Event.SWAP_SETTINGS_SLIPPAGE_TAB_CHANGED, { type: SwapSlippageTypeTab.CUSTOM });
      setTab(SwapSlippageTypeTab.CUSTOM);
    }
    setSlippageText(value);
  };

  const handleToggle = useCallback(
    (open: boolean) => {
      toggle(open);
    },
    [toggle],
  );

  const isWideMobile = useMediaQuery('(max-width: 575px)', { noSsr: true });

  return (
    <>
      {!isWideMobile ? (
        <Menu
          backgroundColor="secondary-04"
          borderColor="secondary-04"
          trigger={<SwapSettingsButton opened={isOpen} onClick={() => track(Event.SWAP_SETTINGS_CLICKED)} />}
          isOpen={isOpen}
          toggle={handleToggle}
          clickable
          hoverable={false}
          showCorner={false}
          py={16}
        >
          <SettingsContent
            handleChange={handleSlippageChange}
            handleTabChange={handleTabChange}
            isHighSlippage={isHighSlippage}
            slippage={slippageText}
            tab={tab}
          />
        </Menu>
      ) : (
        <>
          <SwapSettingsButton
            opened={isOpen}
            onClick={() => {
              track(Event.SWAP_SETTINGS_CLICKED);
              handleToggle(true);
            }}
          />
          <Modal
            className={styles.modal}
            isOpen={isOpen}
            onClose={() => toggle(false)}
            maxWidth="100%"
            stickyTop
            closeIcon
            header={
              <Box height={24} px={22} flexDirection="column" alignItems="center">
                <Text text="app-18-medium" style={{ position: 'absolute', bottom: 0 }}>
                  {t('swap.settings.title')}
                </Text>
              </Box>
            }
          >
            <PageContent height="100%" maxHeight="100%" flexShrink={2} px={0} pt={16}>
              <SettingsContent
                handleChange={handleSlippageChange}
                handleTabChange={handleTabChange}
                isHighSlippage={isHighSlippage}
                slippage={slippageText}
                tab={tab}
              />
            </PageContent>
          </Modal>
        </>
      )}
    </>
  );
});

interface ContentProps {
  handleChange: OnValueChange;
  handleTabChange: (value: string) => void;
  isHighSlippage: boolean;
  slippage: string;
  tab: string;
}

const SettingsContent: FC<ContentProps> = ({ handleChange, handleTabChange, isHighSlippage, slippage, tab }) => {
  const { t } = useTranslation();

  return (
    <Box flexDirection="column" gap="12px" padding="0 16px">
      <Box flexDirection="row" gap="8px" alignItems="center">
        <Text>{t('swap.settings.maxSlippage.title')}</Text>
        <SwapSettingsSlippageTooltip />
      </Box>
      <Box flexDirection="row" gap="12px" alignItems="center">
        <Tabs onChange={(event, value) => handleTabChange(value.toString())} value={tab} selectionFollowsFocus>
          <Tab label={t('swap.settings.maxSlippage.tabs.auto')} value={SwapSlippageTypeTab.AUTO} />
          <Tab label={t('swap.settings.maxSlippage.tabs.custom')} value={SwapSlippageTypeTab.CUSTOM} />
        </Tabs>
        <SwapSettingsSlippageInput disabled={false} value={slippage} handleChange={handleChange} />
      </Box>
      <SwapInlineWarning show={isHighSlippage} content={t('swap.warnings.highSlippage')} />
    </Box>
  );
};
