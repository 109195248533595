import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useAccount } from 'shared/hooks';

import { getPointsSurveyLink } from '../api/queries';

export const useGetPointsSurveyLinkQuery = () => {
  const { address, isConnected } = useAccount();
  const {
    i18n: { language },
  } = useTranslation();

  return useQuery(
    [`points-survey-link-${address}-${language}`],
    () => (address ? getPointsSurveyLink(language, address) : null),
    {
      enabled: isConnected && !!address,
      staleTime: 30000,
      retry: false,
    },
  );
};
