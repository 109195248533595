import { Event, track } from '@rehold-io/data-layer-client';
import { Tickers } from '@rehold-io/tickers';
import { useQueryClient } from '@tanstack/react-query';
import BigNumber from 'bignumber.js';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTransaction } from 'wagmi';
import { waitForTransaction as waitForTransactionWagmi } from 'wagmi/actions';

import { useSignAuthMessage } from 'features/Auth';
import { useHappyMoments } from 'features/HappyMoments';

import { useClaimReferralMutation, useGetReferralOverviewQuery, useGetReferralSettingsQuery } from 'entities/Referral';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { extractError, toBigNumber, waitForTransaction, VALIDATION_ERROR, UNKNOWN_ERROR } from 'shared/lib';
import { logger } from 'shared/lib/logger';
import { notifyAttention, notifyError } from 'shared/lib/notifications';

const PAYOUT_TICKER = Tickers.USDT;

export const useReferral = () => {
  const { t } = useTranslation();
  const { chainId, network } = useNetwork();
  const { address, isConnected } = useAccount();

  const { setValues } = useHappyMoments();
  const queryClient = useQueryClient();

  const { data: referralInviter, isError, isFetching, isInitialLoading, refetch } = useGetReferralOverviewQuery();

  const {
    data: settings,
    isError: isErrorSettings,
    isFetching: isFetchingSettings,
    isInitialLoading: isLoadingSettings,
    refetch: refetchSetting,
  } = useGetReferralSettingsQuery();

  const { data: claimData, isLoading: isLoadingClaim, mutateAsync: claimReferral } = useClaimReferralMutation();
  const [isLoadingHashTx, setLoadingHashTx] = useState(false);

  const { getSignature } = useSignAuthMessage();

  useTransaction({
    enabled: !!claimData?.data?.txHash,
    hash: claimData?.data?.txHash,
    onError: (e) => {
      track(Event.REFERRAL_CLAIM_FAILED, {
        address: address!,
        amount: unclaimedBalance,
        error: extractError(e),
      });
      setLoadingHashTx(false);
      logger.error(e);
    },
    onSuccess: (r) => {
      waitForTransaction(waitForTransactionWagmi({ hash: r.hash }), r.hash)
        .then(() => {
          setValues({ amount: unclaimedBalance, ticker: 'usdt', type: 'referral-claim' });
          track(Event.REFERRAL_CLAIM_SUCCEEDED, {
            address: address!,
            amount: unclaimedBalance,
          });
          queryClient.invalidateQueries({ queryKey: [`referral-claims-${address}`] });
          queryClient.invalidateQueries({ queryKey: [`referral-claims-${address}`] });
        })
        .catch((e) => {
          track(Event.REFERRAL_CLAIM_FAILED, {
            address: address!,
            amount: unclaimedBalance,
            error: extractError(e),
          });

          logger.error(e);
        })
        .finally(() => setLoadingHashTx(false));
    },
  });

  const revShareFeeLoading = isInitialLoading || isLoadingSettings;

  const revShareFee = toBigNumber(referralInviter?.revShareFee || settings?.revShareFee)
    .shiftedBy(2)
    .toString();

  const isDisabledClaim =
    !!referralInviter?.accumulated &&
    BigNumber(settings?.minWithdrawalAmount || '0').gt(BigNumber(referralInviter?.accumulated || '0'));

  const unclaimedBalance = BigNumber(referralInviter?.accumulated || '0')
    .dp(2)
    .toString();

  const claim = useCallback(async () => {
    setLoadingHashTx(true);
    track(Event.REFERRAL_CLAIM_CLICKED, {
      address: address!,
      amount: unclaimedBalance,
    });

    const signature = await getSignature();
    if (!signature) return setLoadingHashTx(false);

    track(Event.REFERRAL_CLAIM_ATTEMPTED, {
      address: address!,
      amount: unclaimedBalance,
    });

    try {
      await claimReferral({ chainId: chainId!, signature });
    } catch (error: any) {
      const errorMessage: string = error?.response?.data?._error?.message;
      const errorLabel: string = error?.response?.data?._error?.label;

      if (errorLabel === VALIDATION_ERROR && errorMessage) {
        notifyError({
          text: errorMessage,
        });
      } else if (errorMessage?.includes('Withdraw amount should be more than')) {
        notifyAttention({
          text: t('referralProgram.referralsPage.minWithdrawal', {
            chain: network?.name,
            value: settings?.minWithdrawalAmount || '0',
          }),
        });
      } else if (errorMessage?.includes('Gas price is too high')) {
        notifyAttention({
          text: t('referralProgram.referralsPage.gasTooHigh', {
            chain: network?.name,
          }),
        });
      } else {
        notifyError({
          text: UNKNOWN_ERROR,
        });
      }

      track(Event.REFERRAL_CLAIM_FAILED, {
        address: address!,
        amount: unclaimedBalance,
        error: extractError(error),
      });

      logger.error(error);
      setLoadingHashTx(false);
    }
  }, [address, unclaimedBalance, getSignature, claimReferral, chainId, network?.name, settings?.minWithdrawalAmount]);

  const referralData = {
    address,
    balanceError: isError && !isFetching,
    balanceFetching: isFetching,
    balanceLoading: isInitialLoading,
    balanceRefetch: () => {
      refetch();
    },
    chainId,
    claim,
    claimDisabled: isDisabledClaim,
    claimIsLoading: isLoadingClaim || isLoadingHashTx,
    claimedBalance: BigNumber(referralInviter?.earned || '0').toFixed(2),
    minWithdrawalAmount: BigNumber(settings?.minWithdrawalAmount || '0')
      .dp(2)
      .toString(),
    network,
    revShareFee,
    revShareFeeError: isError || isErrorSettings,
    revShareFeeFetching: isFetching || isFetchingSettings,
    revShareFeeLoading,
    revShareFeeRefetch: () => {
      refetchSetting();
      if (isConnected) {
        refetch();
      }
    },
    revShareType: referralInviter?.revShareType,
    ticker: PAYOUT_TICKER,
    unclaimedBalance,
  };

  return referralData;
};
