import { Skeleton, useMediaQuery } from '@mui/material';
import { Event, track } from '@rehold-io/data-layer-client';
import { formatPrice } from '@rehold-io/formatters';
import BigNumber from 'bignumber.js';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PerpetualTariff } from 'entities/Perpetual';
import { usePerpetualTariffs } from 'entities/Perpetual/lib/hooks';
import { usePerpetualForm } from 'entities/Perpetual/lib/hooks/usePerpetualForm';
import { useRate, useRateExtended, useRateInterval } from 'entities/Rates';
import { RateProduct } from 'entities/Rates/model/types';

import { CheckIcon, ExpandIcon } from 'shared/assets';
import { formatPercent, formatWithCommas } from 'shared/lib/formats/format';
import { Box, Menu, Text } from 'shared/ui';

import './index.scss';

export const PerpetualsInfo = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const { state, changeTariff } = usePerpetualForm();

  const { tariffs, isLoading } = usePerpetualTariffs();

  useEffect(() => {
    if (!state.tariff && !isLoading) changeTariff(tariffs[0]?.id);
  }, [state.tariff, isLoading]);

  const { price: ratePrice, ready: isRatesReady } = useRateInterval({
    from: state.tariff?.baseTicker,
    to: state.tariff?.quoteTicker,
    product: RateProduct.PERPETUAL,
  });
  const { price: usdPrice, ready: isUsdRatesReady } = useRate({
    from: state.tariff?.quoteTicker,
    to: 'usd',
  });

  const { data: rateExtendedData, isLoading: isRatesLoading } = useRateExtended({
    symbol: state.tariff ? `${state.tariff.baseTicker}/${state.tariff.quoteTicker}` : null,
    product: RateProduct.PERPETUAL,
  });

  const price = (isRatesReady ? ratePrice : rateExtendedData?.[0]?.price) || 0;

  const usdPriceEq = BigNumber(usdPrice).multipliedBy(price);

  const ext = rateExtendedData?.[0];

  const diff = price - (ext?.open || 0);
  const diffPercent = diff / (ext?.open || 1);

  const isStatsLoading = price === 0 || isRatesLoading || !state.tariff || !ext;

  const handlePairSelect = (tariff: PerpetualTariff) => () => {
    changeTariff(tariff.id);
    setOpen(false);

    track(Event.PERPETUAL_PAIR_SELECTED, { baseTicker: tariff.baseTicker, quoteTicker: tariff.quoteTicker });
  };

  const handleMenuToggle = (state: boolean) => {
    setOpen(state);

    if (state) {
      track(Event.PERPETUAL_PAIR_LIST_OPENED);
    }
  };

  const isSmallScreen = useMediaQuery('(max-width: 767px)', { noSsr: true });
  const isTinyScreen = useMediaQuery('(max-width: 450px)', { noSsr: true });

  return (
    <Box className="perpetuals-info">
      <Menu
        backgroundColor="black-01"
        borderColor="secondary-02"
        fullWidth
        widthExtend={20.6}
        menuGap={isSmallScreen ? 18 : 26}
        leftSided={!!isSmallScreen}
        trigger={
          <Box className="perpetuals-info-pair">
            {(isLoading || !state.tariff) && (
              <Skeleton
                width={24}
                height={24}
                sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '50%', transform: 'scale(1, 1)' }}
                animation="wave"
              />
            )}
            {!isLoading && state.tariff && <img alt="token" src={`/static/tickers/${state.tariff?.baseTicker}.svg`} />}
            <Box className="perpetuals-info-pair-text">
              {(isLoading || !state.tariff) && (
                <Skeleton
                  width={128}
                  height={24}
                  sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '4px', transform: 'scale(1, 1)' }}
                  animation="wave"
                />
              )}
              {!isLoading && state.tariff && <Text>{state.tariff?.baseTicker.toUpperCase()}</Text>}
              {!isLoading && state.tariff && <Text>/</Text>}
              {!isLoading && state.tariff && <Text>{state.tariff?.quoteTicker.toUpperCase()}</Text>}
            </Box>
            <ExpandIcon width={20} height={20} className={cx({ active: open })} />
          </Box>
        }
        isOpen={open}
        toggle={handleMenuToggle}
        forceCallback
        forceDesktopLayout
        clickable
        hoverable={false}
        showCorner={false}
        px={0}
        py={0}
        width={isTinyScreen ? 'calc(100vw - 42px)' : '210px'}
        minWidth={isTinyScreen ? 'calc(100vw - 42px)' : '0px'}
      >
        <Box width="100%" zIndex="9999999">
          {tariffs
            .filter((t) => t.isShort === state.isShort)
            .map((tariff) => (
              <Box
                className={cx('perpetuals-info-pair-menu-item', { active: tariff.id === state.tariff?.id })}
                onClick={handlePairSelect(tariff)}
              >
                <Box className="perpetuals-info-pair-menu-item-token">
                  <img alt="token" src={`/static/tickers/${tariff.baseTicker}.svg`} />
                  <Box className="perpetuals-info-pair-menu-item-text">
                    <Text>{tariff.baseTicker.toUpperCase()}</Text>
                    <Text>/</Text>
                    <Text>{tariff.quoteTicker.toUpperCase()}</Text>
                  </Box>
                </Box>
                <CheckIcon width={16} height={16} />
              </Box>
            ))}
        </Box>
      </Menu>
      <Box className="perpetuals-info-stats">
        <Box>
          <Text text="app-16-medium">
            {!isStatsLoading &&
              formatWithCommas(
                formatPrice({
                  value: price,
                  from: state.tariff!.baseTicker,
                  to: state.tariff!.quoteTicker,
                }),
              )}
            {isStatsLoading && (
              <Skeleton
                width={128}
                height={15}
                sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '4px', transform: 'scale(1, 0.80)' }}
                animation="wave"
              />
            )}
          </Text>
          <Text text="app-12-regular" color="gray-01">
            {!isStatsLoading && isUsdRatesReady ? (
              `$${formatWithCommas(
                formatPrice({ from: state.tariff!.baseTicker, to: 'usdt', value: usdPriceEq.toNumber() }),
              )}`
            ) : (
              <Skeleton
                width={128}
                height={20.8}
                sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '4px', transform: 'scale(1, 0.80)' }}
                animation="wave"
              />
            )}
          </Text>
        </Box>
        <Box>
          <Text text="app-12-regular" color="gray-01">
            {!isStatsLoading && t('perpetuals.stats.24hChange')}
            {isStatsLoading && (
              <Skeleton
                width={128}
                height={15}
                sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '4px', transform: 'scale(1, 0.80)' }}
                animation="wave"
              />
            )}
          </Text>
          <Text text="app-16-medium" className={cx({ green: diffPercent > 0, red: diffPercent < 0 })}>
            {!isStatsLoading && `${formatPercent({ value: diffPercent })}%`}
            {isStatsLoading && (
              <Skeleton
                width={128}
                height={20.8}
                sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '4px', transform: 'scale(1, 0.80)' }}
                animation="wave"
              />
            )}
          </Text>
        </Box>
        <Box>
          <Text text="app-12-regular" color="gray-01">
            {!isStatsLoading && t('perpetuals.stats.24hHigh')}
            {isStatsLoading && (
              <Skeleton
                width={128}
                height={15}
                sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '4px', transform: 'scale(1, 0.80)' }}
                animation="wave"
              />
            )}
          </Text>
          <Text text="app-16-medium">
            {!isStatsLoading &&
              formatWithCommas(
                formatPrice({
                  value: ext.high,
                  from: state.tariff!.baseTicker,
                  to: state.tariff!.quoteTicker,
                }),
              )}
            {isStatsLoading && (
              <Skeleton
                width={128}
                height={20.8}
                sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '4px', transform: 'scale(1, 0.80)' }}
                animation="wave"
              />
            )}
          </Text>
        </Box>
        <Box>
          <Text text="app-12-regular" color="gray-01">
            {!isStatsLoading && t('perpetuals.stats.24hLow')}
            {isStatsLoading && (
              <Skeleton
                width={128}
                height={15}
                sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '4px', transform: 'scale(1, 0.80)' }}
                animation="wave"
              />
            )}
          </Text>
          <Text text="app-16-medium">
            {!isStatsLoading &&
              formatWithCommas(
                formatPrice({
                  value: ext.low,
                  from: state.tariff!.baseTicker,
                  to: state.tariff!.quoteTicker,
                }),
              )}
            {isStatsLoading && (
              <Skeleton
                width={128}
                height={20.8}
                sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '4px', transform: 'scale(1, 0.80)' }}
                animation="wave"
              />
            )}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
