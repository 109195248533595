import { Event, track } from '@rehold-io/data-layer-client';
import { formatPrice } from '@rehold-io/formatters';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DualType } from 'entities/Dual';
import { useGetDualTokensQuery } from 'entities/Token';

import { getChainBlockExplorerLink } from 'shared/config';
import { useNetwork } from 'shared/hooks/network';
import { formatDate } from 'shared/lib';
import { Box, Text } from 'shared/ui';

import { HashInfoRow } from '../HashInfoRow';

type DetailsProps = {
  dual: DualType;
};

export const Details: FC<DetailsProps> = observer(
  ({
    dual: {
      baseTicker,
      closedPrice,
      finishAt,
      initialPrice,
      l1ChainId,
      l1TxHash,
      l2ChainId,
      l2TxHash,
      skaleChainId,
      skaleTxHash,
      quoteTicker,
      startedAt,
      version,
    },
  }) => {
    const {
      i18n: { language },
      t,
    } = useTranslation();
    const { tokens } = useGetDualTokensQuery();
    const { network } = useNetwork();

    const l1ExplorerLink = getChainBlockExplorerLink('tx', l1TxHash, l1ChainId);
    const l2ExplorerLink = getChainBlockExplorerLink('tx', l2TxHash, l2ChainId);
    const skaleExplorerLink = getChainBlockExplorerLink('tx', skaleTxHash!, skaleChainId!);

    const openExplorerLink = (url?: string, txHash?: string, chainId?: number) => {
      if (!url) return;

      window.open(url, '_blank');

      track(Event.DUAL_VIEW_EXPLORER_CLICKED, {
        address: txHash!,
        chainId: chainId!,
      });
    };

    const isFinished = new Date().toISOString() > finishAt;

    return (
      <Box mt={24} gap={16}>
        {initialPrice && (
          <DetailRow
            title={t('charts.entryPrice')}
            value={`${formatPrice({
              from: baseTicker,
              to: quoteTicker,
              value: initialPrice,
            })} ${tokens[baseTicker]?.symbol}/${tokens[quoteTicker]?.symbol}`}
          />
        )}
        {closedPrice && (
          <DetailRow
            title={t('charts.closePrice')}
            value={`${formatPrice({
              from: baseTicker,
              to: quoteTicker,
              value: closedPrice,
            })} ${tokens[baseTicker]?.symbol}/${tokens[quoteTicker]?.symbol}`}
          />
        )}
        {l1TxHash && (
          <HashInfoRow
            title="Txn Hash L1"
            hash={l1TxHash}
            onClick={() => openExplorerLink(l1ExplorerLink?.url, l1TxHash, l1ChainId)}
            textTooltip={t('dual.hashL1.tooltip', { chain: network?.name })}
          />
        )}
        {version === 2 && !(Date.now() > new Date(finishAt).getTime() && !l2TxHash) && (
          <HashInfoRow
            title="Txn Hash L2"
            hash={l2TxHash}
            onClick={() => openExplorerLink(l2ExplorerLink?.url, l2TxHash, l2ChainId)}
            textTooltip={t('dual.hashL2.tooltip')}
          />
        )}
        {skaleTxHash && (
          <HashInfoRow
            title="Txn Hash SKALE"
            hash={skaleTxHash}
            onClick={() => openExplorerLink(skaleExplorerLink?.url, skaleTxHash, skaleChainId)}
            textTooltip={t('dual.hashSKALE.tooltip')}
          />
        )}
        {startedAt && <DetailRow title={t('common.started')} value={formatDate(startedAt, language, 'full-date')} />}
        {finishAt && isFinished && (
          <DetailRow title={t('common.finished')} value={formatDate(finishAt, language, 'full-date')} />
        )}
      </Box>
    );
  },
);

type DetailRowProps = {
  title: string;
  value: number | string;
};

const DetailRow: FC<DetailRowProps> = ({ title, value }) => (
  <Box
    gap="8px"
    alignItems="center"
    flexDirection="row"
    justifyContent="space-between"
    display="grid"
    gridTemplateColumns="max-content 1fr max-content"
  >
    <Text text="app-12-regular">{title}</Text>

    <Box width="100%" paddingBottom={3} height="100%" justifyContent="flex-end">
      <Box sx={{ borderBottomWidth: 1, borderStyle: 'dashed' }} borderColor="secondary-04" />
    </Box>

    <Text text="app-12-regular" color="primary-01">
      {value}
    </Text>
  </Box>
);
