import { Box } from 'shared/ui';

import { Page } from '../types';

import { LanguagePage } from './LanguagePage';
import { NetworkPage } from './NetworkPage';
import { RootPage } from './RootPage';
import { WalletPage } from './WalletPage';

interface Props {
  onChangePage: (page: Page) => void;
  page: Page;
  toggle: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MainMenu: React.FC<Props> = ({ onChangePage, toggle }) => (
  <Box>
    <LanguagePage onPressBack={() => onChangePage('root')} toggle={toggle} />
    <NetworkPage onPressBack={() => onChangePage('root')} toggle={toggle} />
  </Box>
);

export const MainMenuMobile: React.FC<Props> = ({ onChangePage, page, toggle }) => {
  let child;

  if (page === 'network') child = <NetworkPage onPressBack={() => onChangePage('root')} toggle={toggle} />;
  if (page === 'language') child = <LanguagePage onPressBack={() => onChangePage('root')} toggle={toggle} />;
  if (page === 'wallet') child = <WalletPage onPressBack={() => onChangePage('root')} toggle={toggle} />;
  if (page === 'root') child = <RootPage onChangePage={onChangePage} />;

  return (
    <Box overflow="hidden" height="calc(100dvh - 67px)">
      {child}
    </Box>
  );
};
