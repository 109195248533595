import { ParsedUrlQueryInput, stringify } from 'querystring';
import { Event, track } from '@rehold-io/data-layer-client';
import { FC, useContext, useCallback, useMemo, createContext } from 'react';
import { useTranslation } from 'react-i18next';

import { usePoints } from 'widgets/Points/lib';

import { logger } from 'shared/lib/logger';
import { Box, BoxProps, Spinner, Text } from 'shared/ui';

import { ReactComponent as FacebookIcon } from './assets/facebook.svg';
import { ReactComponent as ShareIcon } from './assets/share.svg';
import { ReactComponent as TelegramIcon } from './assets/telegram.svg';
import { ReactComponent as TwitterIcon } from './assets/twitter.svg';
import { ReactComponent as WhatsAppIcon } from './assets/whats_app.svg';

export const SHARE_WIDGET_ICONS = {
  facebook: FacebookIcon,
  share: ShareIcon,
  telegram: TelegramIcon,
  twitter: TwitterIcon,
  whatsApp: WhatsAppIcon,
};

export const SHARE_WIDGET_ACTIONS = {
  facebook: (text: string, url: string) => {
    share('https://www.facebook.com/sharer.php?', {
      u: `https://${url}`,
    });
  },
  share: (text: string, url: string) => {
    try {
      navigator.share({
        text: `${text} – https://${url}`,
      });
    } catch (error: any) {
      if (error.name === 'AbortError') return;
      logger.error(error);
    }
  },
  telegram: (text: string, url: string) => {
    share('https://t.me/share/url?', {
      text,
      url: `https://${url}`,
    });
  },
  twitter: (text: string, url: string) => {
    share('https://twitter.com/intent/tweet?', {
      text,
      url,
    });
  },
  whatsApp: (text: string, url: string) => {
    share(isMobileBrowser() ? 'whatsapp://send?' : 'https://web.whatsapp.com/send?', {
      text: `${text} – https://${url}`,
    });
  },
};

export const SHARE_WIDGET_CHECKS = {
  share: () => hasNavigatorShare() && isMobileBrowser(),
} as {
  [key in keyof typeof SHARE_WIDGET_ACTIONS]: () => boolean;
};

export interface ShareWidgetContextProps {
  code: string;
  text: string;
  url: string;
}

export const ShareWidgetContext = createContext<ShareWidgetContextProps>({
  code: '',
  text: '',
  url: '',
});

export interface ShareWidgetProps {
  code: string;
  names?: Array<keyof typeof SHARE_WIDGET_ACTIONS>;
  url: string;
}

export const ShareWidget: FC<ShareWidgetProps> = ({
  code,
  names = ['share', 'telegram', 'twitter', 'facebook', 'whatsApp'],
  url,
}) => {
  const { t } = useTranslation();

  const { settings, isSettingsError, isSettingsLoading, settingsRefetch } = usePoints();

  const text = t('points.shareLink.text', { amount: settings?.welcome });

  const widgetContext = useMemo(
    () => ({
      code,
      text,
      url,
    }),
    [code, text, url],
  );

  if (isSettingsLoading) {
    return (
      <Box height={250} alignItems="center" justifyContent="center">
        <Spinner />
      </Box>
    );
  }

  if (!settings || isSettingsError) {
    return (
      <Box flexDirection="row" ml={8} alignSelf="center">
        <Box onClick={settingsRefetch}>
          <Text color="red-01" fontWeight="bold">
            {t('common.fetchRetry')}
          </Text>
        </Box>
      </Box>
    );
  }

  return (
    <Box flexDirection="row" alignItems="center" marginBottom={24}>
      <ShareWidgetContext.Provider value={widgetContext}>
        {names.map((name, index) => (
          <ShareWidgetItem key={name} name={name} mr={index < names.length - 1 ? 12 : undefined} />
        ))}
      </ShareWidgetContext.Provider>
    </Box>
  );
};

export interface ShareWidgetItemProps extends BoxProps {
  name: keyof typeof SHARE_WIDGET_ACTIONS;
}

export const ShareWidgetItem: FC<ShareWidgetItemProps> = ({ children, name, ...rest }) => {
  const { code, text, url } = useContext(ShareWidgetContext);

  const handlePress = useCallback(() => {
    SHARE_WIDGET_ACTIONS[name](text, url);

    track(Event.POINT_SHARE_CLICKED, { code, provider: name });
  }, [name, url, text, code]);

  const check = useMemo(() => {
    const checker = SHARE_WIDGET_CHECKS[name];

    return checker === undefined || checker();
  }, [name]);

  if (check) {
    const Icon = SHARE_WIDGET_ICONS[name];

    return (
      <Box
        onClick={handlePress}
        justifyContent="center"
        alignItems="center"
        width={48}
        height={48}
        borderRadius={24}
        {...rest}
      >
        <Icon width="100%" height="100%" />
      </Box>
    );
  }

  return null;
};

export const hasNavigatorShare = () => typeof navigator.share === 'function';

export const isMobileBrowser = () => /Android|iPhone/i.test(navigator.userAgent);

export const share = (url: string, params: ParsedUrlQueryInput) => {
  window.open(url + stringify(params), '_blank');
};
