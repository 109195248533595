import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { ForwardIcon } from 'shared/assets';
import { useDetectMobileDevice } from 'shared/hooks';
import { Box } from 'shared/ui';

import styles from './FilterQuoteTicker.module.scss';

export const OVERRIDDEN_MAP: Record<string, string> = {
  'usdt.e': 'usdt',
  fusdt: 'usdt',

  'usdc.e': 'usdc',
  usdbc: 'usdc',

  'dai.e': 'dai',

  btcb: 'btc',
  'btc.b': 'btc',
  wbtc: 'btc',
  rbtc: 'btc',
  wrbtc: 'btc',

  weth: 'eth',
  'weth.e': 'eth',

  wbnb: 'bnb',

  wmatic: 'matic',
  wpol: 'pol',

  wavax: 'avax',

  wftm: 'ftm',
};

export const OVERRIDDEN_TICKERS = Object.entries(OVERRIDDEN_MAP).reduce((acc, [key, value]) => {
  if (acc[value]) {
    acc[value].push(key);
    return acc;
  }
  acc[value] = [key];
  return acc;
}, {} as Record<string, string[]>);

export const TICKERS_ORDER = ['usdt', 'usdc', 'dai', 'eurc', 'btc', 'eth', 'pol', 'matic', 'avax', 'ftm'].reduce(
  (acc, ticker, index) => {
    acc[ticker] = index + 1;
    return acc;
  },
  {} as Record<string, number>,
);

interface Props {
  onSelect: (selected: string) => void;
  selected?: string | null;
  tickers: string[];
}

export const FilterQuoteTicker: React.FC<Props> = ({ selected, onSelect, tickers }) => {
  const isMobileDevice = useDetectMobileDevice();

  const scrollRef = useRef<any>(null);
  const [showRightButton, setShowRightButton] = useState(false);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [lastScrollLeft, setLastScrollLeft] = useState(0);

  const isScrollingRef = useRef(false);

  const checkScroll = () => {
    if (isScrollingRef.current) {
      return;
    }

    isScrollingRef.current = true;

    requestAnimationFrame(() => {
      if (scrollRef?.current) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
        const isOverflowing = scrollWidth > clientWidth;
        const atRightEnd = scrollLeft + clientWidth >= scrollWidth - 10;
        const atLeftEnd = scrollLeft <= 10;

        setShowRightButton(isOverflowing && !atRightEnd);
        setShowLeftButton(isOverflowing && !atLeftEnd);
        setLastScrollLeft(scrollLeft);
        isScrollingRef.current = false;
      }
    });
  };

  useEffect(() => {
    const scrollContainer = scrollRef?.current;
    checkScroll(); // Initial check on mount
    scrollContainer?.addEventListener('scroll', checkScroll);

    return () => {
      scrollContainer?.removeEventListener('scroll', checkScroll);
    };
  }, [checkScroll]);

  const scrollRight = () => {
    if (scrollRef?.current) {
      const newScrollPosition = lastScrollLeft + 150; // Adjust the scrolling step as needed
      scrollRef.current.scrollLeft = newScrollPosition;
      setLastScrollLeft(newScrollPosition);
    }
  };

  const scrollLeft = () => {
    if (scrollRef?.current) {
      const newScrollPosition = lastScrollLeft - 150; // Adjust the scrolling step as needed
      scrollRef.current.scrollLeft = newScrollPosition;
      setLastScrollLeft(newScrollPosition);
    }
  };

  return (
    <Box position="relative" flexDirection="row" paddingY="8px">
      <div className={`${styles.tickers} hide-scroll`} ref={scrollRef}>
        {tickers.map((ticker, index) => (
          <Box
            className={classnames(styles['animated-ticker'], {
              [styles['hoverable-ticker']]: !isMobileDevice && selected !== ticker,
            })}
            key={ticker}
            height={24}
            paddingRight={index === tickers.length - 1 ? 0 : 8}
            paddingLeft={index === 0 ? 0 : 8}
            alignItems="center"
            justifyContent="center"
            color={selected === ticker ? 'primary-01' : 'secondary-03'}
            onClick={() => onSelect(ticker)}
          >
            {ticker.toUpperCase()}
          </Box>
        ))}
      </div>
      <Box
        className={classnames(styles['scroll-button'], styles['right-button'], {
          [styles.hidden]: !showRightButton,
          [styles['scroll-hoverable']]: !isMobileDevice,
        })}
        onClick={scrollRight}
      >
        <ForwardIcon color="inherit" strokeWidth="2px" />
      </Box>
      <Box
        className={classnames(styles['scroll-button'], styles['left-button'], {
          [styles.hidden]: !showLeftButton,
          [styles['scroll-hoverable']]: !isMobileDevice,
        })}
        onClick={scrollLeft}
      >
        <ForwardIcon color="inherit" strokeWidth="2px" />
      </Box>
    </Box>
  );
};
