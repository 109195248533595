import { Event, track } from '@rehold-io/data-layer-client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PathPatterns } from 'shared/lib';
import { Tab, Tabs } from 'shared/ui';

export const SwapTabs: React.FC<{ onChange: (value: string) => void; value: string }> = ({ value, onChange }) => {
  const { t } = useTranslation();

  const router = useNavigate();
  const tab = value;

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: boolean | number | string) => {
      if (newValue === PathPatterns.Swap) track(Event.SWAP_TAB_CLICKED, { tab: newValue as string });
      if (newValue === PathPatterns.Buy) track(Event.ONRAMP_TAB_CLICKED, { tab: newValue as string });
      onChange(newValue.toString());
    },
    [router],
  );

  return (
    <Tabs style={{ width: 'calc(12rem + 15%)' }} onChange={handleChange} value={tab} selectionFollowsFocus>
      <Tab label={t('swap.tabs.swap')} value={PathPatterns.Swap} />
      <Tab label={t('swap.tabs.buy')} value={PathPatterns.Buy} />
    </Tabs>
  );
};
