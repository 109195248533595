import { Event, track } from '@rehold-io/data-layer-client';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BoxProps, Box, Text, ButtonCopy, Spinner } from 'shared/ui';

import { useReferralCodeLink } from '../../lib/useReferralCodeLink';
import { ShareWidget } from '../ShareWidget';

export const ReferralProgramInviteFriends: FC = () => {
  const { code: inviteCode, isError, isFetching, link: inviteLink, refetch } = useReferralCodeLink();
  const { t } = useTranslation();

  const onCopy = useCallback(() => {
    track(Event.REFERRAL_SHARE_COPIED, { code: inviteCode });
  }, [inviteCode]);

  return (
    <Box>
      <Box pb={24}>
        <Box mb={16} flexDirection="row" alignItems="center">
          <Text>{t('referralProgram.mainPage.share')}</Text>
        </Box>
        <InputWrap p={8}>
          <Box flex={1} px={16}>
            {isFetching && (
              <Box ml={8}>
                <Spinner size={16} />
              </Box>
            )}
            {!!inviteLink && (
              <Box flex={1}>
                <Text color="secondary-03">{inviteLink}</Text>
              </Box>
            )}
            {isError && (
              <Box flexDirection="row">
                {!inviteLink && <Text color="red-01">{t('common.fetchError')} </Text>}
                <Box onClick={refetch}>
                  <Text color="red-01" fontWeight="bold">
                    {t('common.fetchRetry')}
                  </Text>
                </Box>
              </Box>
            )}
          </Box>
          <ButtonCopy
            disabled={!inviteLink}
            variant="secondary"
            copyText={inviteLink}
            onClick={onCopy}
            data-id="copy-invite"
          >
            {t('referralProgram.mainPage.copy')}
          </ButtonCopy>
        </InputWrap>
      </Box>
      {!!inviteLink && (
        <Box minHeight={82}>
          <Text mb={16}>{t('referralProgram.mainPage.moreShare')}</Text>
          <ShareWidget url={inviteLink} code={inviteCode} />
        </Box>
      )}
    </Box>
  );
};

export const InputWrap: FC<BoxProps> = (props) => (
  <Box
    flexDirection="row"
    justifyContent="space-between"
    alignItems="center"
    height={64}
    borderColor="secondary-02"
    borderRadius={16}
    {...props}
    sx={{ borderWidth: 1, ...props.sx }}
  />
);
