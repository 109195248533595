import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useGetPointsSurveyLinkQuery } from 'entities/Points';

import { useAccount } from 'shared/hooks';
import { LOCAL_STORAGE_SURVEY_CACHE } from 'shared/lib';
import { Box, Button, CurrencyIcon, Modal, PageContent, Text } from 'shared/ui';

import { Spinner } from './Spinner';
import styles from './index.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const applyCache = (address: string, url: string, value: 'visited' | 'skipped') => {
  const cache = JSON.parse(localStorage.getItem(LOCAL_STORAGE_SURVEY_CACHE) || '{}');

  const addressCache = cache[address] || {};

  const newAddressCache = { ...addressCache, [url]: value };
  const newCache = { ...cache, [address]: newAddressCache };

  localStorage.setItem(LOCAL_STORAGE_SURVEY_CACHE, JSON.stringify(newCache));
};

export const DailyQuestSurveyModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { address } = useAccount();

  const { data, isLoading } = useGetPointsSurveyLinkQuery();

  const isWideMobile = useMediaQuery('(max-width: 575px)', { noSsr: true });

  const handleVisit = () => {
    window.open(data?.url, '_blank');
    if (data && address) applyCache(address, data.url, 'visited');
    onClose();
  };

  const handleSkip = () => {
    if (data && address) applyCache(address, data.url, 'skipped');
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxWidth={isWideMobile ? '100%' : 500} closeIcon>
      <PageContent height="100%" maxHeight="100%" flexShrink={2} px={22} pb={22}>
        <Box flexDirection="column" alignItems="center" justifyContent="center" width="100%">
          {!isLoading && <CurrencyIcon ticker="redy" size={80} />}
          {isLoading && <Spinner />}

          {!isLoading && (
            <Text className={styles['text-container']} mt={12} textAlign="center" text="app-22-medium">
              {t('modals.dailyQuest.survey.title')}
            </Text>
          )}
          {!isLoading && (
            <Button variant="primary" size="large" mt={24} onClick={handleVisit}>
              {t('modals.dailyQuest.survey.action')}
            </Button>
          )}
          {!isLoading && (
            <Button variant="secondary-solid" size="medium" mt={8} onClick={handleSkip}>
              {t('modals.dailyQuest.survey.skip')}
            </Button>
          )}
        </Box>
      </PageContent>
    </Modal>
  );
};
