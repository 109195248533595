import { useTheme } from '@mui/material';
import { useModal } from '@rehold-io/connectkit';
import { Event, track } from '@rehold-io/data-layer-client';
import BigNumber from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useSignAuthMessage } from 'features/Auth';

import { useGetSettingsDualsQuery } from 'entities/Dual';
import { useRate } from 'entities/Rates';
import { waitForUserAction } from 'entities/User';

import { HintIcon, ReplayIcon } from 'shared/assets';
import { useAccount } from 'shared/hooks';
import { Box, Text, Tooltip, FormControlSwitch, ButtonIcon } from 'shared/ui';

import { useAutoReplay } from '../../lib/hooks/useAutoReplay';

type Props = {
  error?: string;
  inputAmount: number;
  inputTicker: string;
  isDisabled?: boolean;
  isDisabledValidation?: boolean;
  isLoading?: boolean;
  onChange?: (value: boolean) => Promise<boolean>;
};

export const AutoReplayField: FC<Props> = observer(
  ({ error, inputAmount, inputTicker, isDisabled, isLoading, onChange, isDisabledValidation }) => {
    const { t } = useTranslation();
    const { enabled, isLoading: isLoadingAutoReplay, setEnabled, setIsLoading } = useAutoReplay();

    const { signAuthMessage, validateSign } = useSignAuthMessage();
    const { data } = useGetSettingsDualsQuery();
    const { address } = useAccount();
    const { setOpen } = useModal();

    const rateObj = useRate({ from: inputTicker, to: 'usd', inputTicker });
    const price = rateObj?.price;
    const inputValue = BigNumber(price || 0).multipliedBy(inputAmount || 0);

    const { colors } = useTheme();

    const isErrorMinInputAmount = data?.autoReplayMinInputAmountUSD
      ? inputValue.lt(data.autoReplayMinInputAmountUSD)
      : false;
    const errorMessage = isDisabledValidation
      ? false
      : isErrorMinInputAmount
      ? t('dual.autoReplay.available', { value: `${data?.autoReplayMinInputAmountUSD}` })
      : error;

    useEffect(() => {
      if (isDisabledValidation) return;
      if (enabled && (isErrorMinInputAmount || isDisabled)) {
        setEnabled(false);
      }
    }, [isErrorMinInputAmount, isDisabled, enabled, setEnabled, isDisabledValidation]);

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!address) return setOpen(true);

      const value = e.target.checked;

      const isSigned = await validateSign();

      if (!isSigned) {
        setIsLoading(true);
        try {
          const isSuccess = await waitForUserAction(signAuthMessage(), 'message');

          setIsLoading(false);
          if (!isSuccess) return;
        } catch {
          setIsLoading(false);
          return;
        }
      }

      setEnabled(value);

      track(Event.DUAL_FORM_AUTO_REPLAY_SWITCH_CHANGED, { checked: value });

      if (onChange) {
        const isSuccess = await onChange(value);
        if (!isSuccess) setEnabled(false);
      }
    };

    return (
      <Box
        height={38}
        display="flex"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
        data-id="auto-replay-switch"
        paddingY={8}
      >
        <FormControlSwitch
          disabled={enabled || isDisabled || isDisabledValidation ? false : isErrorMinInputAmount}
          checked={enabled}
          onChange={handleChange}
          isLoading={isLoading || isLoadingAutoReplay}
          tooltip={errorMessage}
          label={
            <Box gap="8px" alignItems="center" flexDirection="row">
              <ReplayIcon width={24} height={20} color={enabled ? colors['primary-01'] : colors['secondary-03']} />
              <Text text="app-14-medium" color={enabled ? 'primary-01' : 'secondary-03'}>
                {t('dual.autoReplay.name')}
              </Text>
              <Tooltip title={t('dual.autoReplay.description')}>
                <Box>
                  <ButtonIcon
                    isOutlined
                    sx={{ padding: '2px' }}
                    data-id="hint-auto-replay"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <HintIcon width={16} height={16} />
                  </ButtonIcon>
                </Box>
              </Tooltip>
            </Box>
          }
          justifyContent="space-between"
        />
      </Box>
    );
  },
);
