import { useMediaQuery } from '@mui/material';
import { Event, track } from '@rehold-io/data-layer-client';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { formatSuffixNumber, useGetPointsStatsQuery } from 'entities/Points';

import { useAccount, useIsMobile } from 'shared/hooks';
import { PathPatterns } from 'shared/lib';
import { Box, CurrencyIcon, Spinner, Text } from 'shared/ui';

import styles from './styles.module.scss';

export const NavPoints: FC = observer(() => {
  const { address } = useAccount();

  const navigate = useNavigate();
  const location = useLocation();

  const handlePress = useCallback(() => {
    track(Event.MENU_CLICKED, { type: 'points', from: location.pathname });
    if (location.pathname !== PathPatterns.Points) {
      navigate(PathPatterns.Points);
    }
  }, [navigate, location]);

  const { data } = useGetPointsStatsQuery();

  const isSmallDesktop = useMediaQuery('(max-width: 920px)', { noSsr: true });

  const isMobile = useIsMobile();

  if (isMobile) return;

  return (
    <Box className={cx(styles.trigger)} pr={isSmallDesktop ? 0 : 12} onClick={handlePress}>
      <CurrencyIcon className={styles.redy} size={36} ticker="redy" />
      {!isSmallDesktop && <Text mx={8}>REDY</Text>}
      {!isSmallDesktop && (
        <>
          {data && address && (
            <Box
              justifyContent="center"
              alignItems="center"
              minWidth={20}
              height={20}
              bg="primary-01"
              borderRadius={20}
              px={5}
            >
              <Text color="black-01">{formatSuffixNumber(data?.balance || 0)}</Text>
            </Box>
          )}
          {!data && address && <Spinner size={20} />}
        </>
      )}
    </Box>
  );
});
