import { Event, track } from '@rehold-io/data-layer-client';
import { getAmountPrecisionBySymbol } from '@rehold-io/formatters';
import debounce from 'lodash.debounce';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberFormatValues, NumericFormat, OnValueChange } from 'react-number-format';

import { Card } from 'shared/ui/Card';

import { PerpetualsBalanceAction } from '../types';

import styles from './styles.module.scss';

interface AmountHandlerProps {
  amount: string;
  className?: string;
  maxAmount: number;
  onAmountChange: (amount: string, ticker: string) => void;
  ticker: string;
  type: PerpetualsBalanceAction;
}

const INPUT_DEBOUNCE = 500;

export const PerpetualsFormAmountField: FC<AmountHandlerProps> = ({
  amount,
  maxAmount,
  className,
  onAmountChange,
  ticker,
  type,
}) => {
  const { t } = useTranslation();

  const trackDebounced = useMemo(() => {
    if (type === PerpetualsBalanceAction.DEPOSIT) {
      return debounce(() => track(Event.PERPETUAL_DEPOSIT_MODAL_INPUT_CHANGED), INPUT_DEBOUNCE);
    }
    if (type === PerpetualsBalanceAction.WITHDRAW) {
      return debounce(() => track(Event.PERPETUAL_WITHDRAW_MODAL_INPUT_CHANGED), INPUT_DEBOUNCE);
    }
  }, []);

  const handleAmountChange: OnValueChange = (values) => {
    onAmountChange(values.value, ticker);

    trackDebounced?.();
  };

  const withValueLimit = ({ floatValue }: NumberFormatValues) => (floatValue ?? 0) <= maxAmount;

  return (
    <div className={className}>
      <Card variant="secondary" label={t('modals.perpetuals.amount')} isOutlined className={className}>
        <div className={styles.cardContent}>
          <NumericFormat
            data-id="input-amount"
            className={styles.input}
            displayType="input"
            type="text"
            inputMode="decimal"
            onValueChange={handleAmountChange}
            value={amount}
            isAllowed={withValueLimit}
            allowNegative={false}
            allowLeadingZeros={false}
            decimalScale={getAmountPrecisionBySymbol(ticker)}
            allowedDecimalSeparators={['.', ',']}
            defaultValue={0}
            placeholder="0"
            valueIsNumericString
          />
        </div>
      </Card>
    </div>
  );
};
