import { useMediaQuery } from '@mui/material';
import { track, Event } from '@rehold-io/data-layer-client';
import { observer } from 'mobx-react-lite';
import { FC, isValidElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { storageSignature } from 'features/Auth';

import { useUserMutation } from 'entities/User';

import { ForwardIcon, LanguageEnIcon } from 'shared/assets';
import { useAccount } from 'shared/hooks';
import { removeQueryParam } from 'shared/lib';
import { DEFAULT_LANGUAGE, LANGUAGES_ICONS, SUPPORTED_LANGUAGES } from 'shared/locales';
import { Text } from 'shared/ui';
import { Box } from 'shared/ui/Box';

import { MenuProps } from '../../types';
import { PageHeader } from '../PageHeader';

import { LanguageItem } from './item';
import { LanguageModal } from './modal';
import styles from './styles.module.scss';

export const LANGUAGES = Object.keys(SUPPORTED_LANGUAGES);

export const LanguagePage: FC<MenuProps> = observer(({ onPressBack, toggle }) => {
  const {
    i18n: { changeLanguage, language },
    t,
  } = useTranslation();
  const { address } = useAccount();

  const { mutateAsync } = useUserMutation();

  const [isOpen, setOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setOpen(true);
    toggle(false);
  }, []);

  const handleChangeLanguage = useCallback(
    (language: string) => {
      track(Event.LANGUAGE_CHANGED, { language });
      changeLanguage(language);
      removeQueryParam('language');

      if (!address) return;
      const signature = storageSignature.get(address);
      if (signature) mutateAsync({ language, signature });
    },
    [changeLanguage, address, mutateAsync],
  );

  const handleMap = useCallback(
    (lang: string) => (
      <>
        {SUPPORTED_LANGUAGES[lang] && (
          <LanguageItem
            key={lang}
            lang={lang}
            title={SUPPORTED_LANGUAGES[lang]}
            active={language === lang}
            changeLanguage={handleChangeLanguage}
            toggle={toggle}
          />
        )}
      </>
    ),
    [language, handleChangeLanguage, toggle],
  );

  const Icon = LANGUAGES_ICONS[language];
  const icon = Icon && <Icon width={24} height={24} />;

  const isMobile = useMediaQuery('(max-width: 575px)', { noSsr: true });

  if (isMobile)
    return (
      <>
        {onPressBack && <PageHeader onPressBack={onPressBack}>{t('menu.main.language')}</PageHeader>}
        <Box overflow="auto" flexBasis="0px" flexGrow="1">
          <Box alignItems="flex-start" justifyContent="flex-start" flexDirection="row" flexWrap="wrap">
            {LANGUAGES.map(handleMap)}
          </Box>
        </Box>
      </>
    );

  return (
    <>
      <LanguageModal isOpen={isOpen} onClose={() => setOpen(false)} />
      <Box
        className={styles['language-button']}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        height={56}
        px={24}
        py={16}
        onClick={handleOpenModal}
        data-id="newtwork-switch"
      >
        <Box flexDirection="row" alignItems="center">
          {isValidElement(icon) ? icon : <LanguageEnIcon width={24} height={24} />}
          <Text ml={8} text="app-14-medium" color="white-01">
            {SUPPORTED_LANGUAGES[language] ?? SUPPORTED_LANGUAGES[DEFAULT_LANGUAGE]}
          </Text>
        </Box>
        <Box color="white-01">
          <ForwardIcon width={16} height={16} strokeWidth={2} />
        </Box>
      </Box>
    </>
  );
});
