import { useEffect } from 'react';

import { useAccount } from 'shared/hooks';
import { logger } from 'shared/lib/logger';
import { waitForTransfer } from 'shared/lib/waitForTransfer';

import { UserTransfer, storageTransfers } from '../lib';

import { TransferStatus } from './types';
import { useGetTransfersQuery } from './useGetTransfersQuery';

export const useHandlePendingTransfers = () => {
  const { address } = useAccount();

  const { data } = useGetTransfersQuery(TransferStatus.PENDING, { refetchInterval: 60 * 1000 });

  useEffect(() => {
    if (data && address) {
      const storedPendingTransfers = storageTransfers
        .getPendingTransfers()
        .filter((transfer) => transfer.address === address);

      const mappedData: UserTransfer[] = data.map((transfer) => ({
        address: address.toString(),
        amount: transfer.amount,
        chainId: transfer.chainId,
        ticker: transfer.ticker.toUpperCase(),
        txHash: transfer.txHash,
        type: transfer.type,
      }));

      const transfers = [...mappedData, ...storedPendingTransfers];

      transfers.forEach(async (transfer) => {
        try {
          await waitForTransfer(transfer);
        } catch (e: any) {
          logger.error(e);
        }
      });
    }
  }, [data, address]);
};
