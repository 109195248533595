import { FC, PropsWithChildren } from 'react';

import { BackIcon } from 'shared/assets';
import { Box, BoxProps, Text } from 'shared/ui';

export interface PageHeaderProps {
  onPressBack?: BoxProps['onClick'];
}

export const PageHeader: FC<PropsWithChildren<PageHeaderProps>> = ({ children, onPressBack }) => (
  <Box
    flexDirection="row"
    alignItems="center"
    height={72}
    padding={24}
    onClick={onPressBack}
    borderBottom="1px solid"
    borderColor="secondary-02"
  >
    {onPressBack && (
      <Box mr={16} onClick={onPressBack} color="white-01">
        <BackIcon width={24} height={24} />
      </Box>
    )}
    <Text text="app-16-medium">{children}</Text>
  </Box>
);
