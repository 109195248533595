import { Event, track } from '@rehold-io/data-layer-client';
import { formatAmount, getAmountPrecisionBySymbol } from '@rehold-io/formatters';
import BigNumber from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useCallback } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useBalance } from 'wagmi';

import { useGetMaxAmountForCreate } from 'widgets/Dual/lib/useGetMaxAmountForCreate';
import { DashboardFormValues } from 'widgets/Dual/model/form';

import { useLimitDualInfo } from 'features/LimitsDual';

import { useGetSettingsDualsQuery, useInputTickerLimits } from 'entities/Dual';
import { ratesStore } from 'entities/Rates';
import { AmountWithTicker } from 'entities/Token';
import { useGetDualTokenByTickerAndChain } from 'entities/Token/model/useGetDualTokenByTickerAndChain';

import { useAccount } from 'shared/hooks';
import { toBigNumber } from 'shared/lib';
import { Error } from 'shared/ui';

import { UserWalletBalance } from '../../lib/UserWalletBalance';
import styles from '../DualCreateForm/DualCreateForm.module.scss';

interface AmountHandlerFieldProps {
  className?: string;
}

export const AmountHandlerField: React.FC<AmountHandlerFieldProps> = observer(({ className }) => {
  const { t } = useTranslation();
  const { control, getValues, setValue, trigger, clearErrors } = useFormContext<DashboardFormValues>();
  const [inputAmount, inputTicker, tariff, chainId] = useWatch({
    control,
    name: ['inputAmount', 'inputTicker', 'tariff', 'chainId'],
  });

  const tickers = tariff ? [tariff.baseTicker, tariff.quoteTicker] : [];

  const { limit } = useInputTickerLimits(inputTicker, tariff, chainId);
  const { address } = useAccount();
  const token = useGetDualTokenByTickerAndChain(inputTicker, chainId);

  const { data: dualSettings } = useGetSettingsDualsQuery(chainId);

  const { enabled } = useLimitDualInfo();

  const { data } = useBalance({
    address,
    chainId,
    token: token?.address,
  });

  const maxAmountWithGas = useGetMaxAmountForCreate(
    limit.max,
    data?.formatted ? toBigNumber(data?.formatted).toNumber() : 0,
    inputTicker,
  );
  const minAmount = limit.min;
  const maxAmount = limit.max;

  const initialAmount = useMemo(() => {
    if (!data || !data.formatted || toBigNumber(data.formatted).eq(0)) {
      return maxAmount.toString();
    }

    if (BigNumber(maxAmountWithGas).lt(minAmount)) return maxAmount.toString();

    return BigNumber.min(maxAmountWithGas, data.formatted).toFixed(
      getAmountPrecisionBySymbol(inputTicker),
      BigNumber.ROUND_DOWN,
    );
  }, [data, maxAmountWithGas, maxAmount, minAmount, inputTicker]);

  useEffect(() => {
    setValue('inputAmount', initialAmount);
  }, [setValue, inputTicker, initialAmount]);

  const onBalancePress = useCallback(() => {
    setValue('inputAmount', initialAmount);
    track(Event.DUAL_BALANCE_CLICKED, { ticker: inputTicker });
  }, [inputTicker, initialAmount, setValue]);

  const rules = useMemo(
    () => ({
      validate: async (value: string) => {
        const rate = ratesStore.getRate(`${inputTicker}/usd`, undefined, inputTicker);
        const price = rate?.price;

        const minDualViewLimit = BigNumber(dualSettings?.limitMinInputAmountUSD || 0).div(price || 1);

        const minDualLimit = BigNumber.max(minDualViewLimit.multipliedBy(0.99), minAmount);

        if (enabled && dualSettings && BigNumber(value).lt(minDualLimit)) {
          return t('dual.validation.amountMore', { more: formatAmount({ symbol: inputTicker, value: minDualLimit }) });
        }

        if (Number(value) < Number(minAmount)) {
          return t('dual.validation.amountMore', { more: formatAmount({ symbol: inputTicker, value: minAmount }) });
        }
        if (Number(value) > Number(maxAmount)) {
          return t('dual.validation.amountLess', { less: formatAmount({ symbol: inputTicker, value: maxAmount }) });
        }

        return true;
      },
    }),
    [maxAmount, minAmount, t, inputTicker, dualSettings, enabled],
  );

  return (
    <div className={`${className} ${styles.amount}`}>
      <Controller
        control={control}
        name="inputAmount"
        rules={rules}
        render={({ field: { onChange }, fieldState: { error } }) => {
          const handleChange = (amount: string, ticker: string) => {
            const { inputAmount: prevInputAmount, inputTicker: prevInputTicker } = getValues();

            if (prevInputAmount !== amount) onChange(amount);
            if (ticker !== prevInputTicker) {
              clearErrors();
              setValue('inputTicker', ticker);
              return;
            }
            if (error?.message) trigger();
          };

          return (
            <Error message={error?.message} left={16}>
              <AmountWithTicker
                className={styles['amount-field']}
                amount={inputAmount}
                ticker={inputTicker}
                onAmountChange={handleChange}
                tickers={tickers}
                chainId={chainId}
              />
            </Error>
          );
        }}
      />
      <UserWalletBalance ticker={inputTicker} onPress={onBalancePress} />
    </div>
  );
});
