import { Event, track, TrackFunctionProperties } from '@rehold-io/data-layer-client';
import { useState } from 'react';
import { parseUnits } from 'viem';
import { useContractRead, useContractWrite, usePrepareContractWrite, usePublicClient } from 'wagmi';
import { waitForTransaction as waitForTransactionWagmi } from 'wagmi/actions';

import { useSwapTokenByAddress } from 'entities/Swap/lib/hooks';
import { checkDoubleApproveToken } from 'entities/Token';
import { waitForUserAction } from 'entities/User';

import { NATIVE_TOKEN_ADDRESS } from 'shared/config';
import { tokenAbi } from 'shared/config/chains/abi';
import { useAccount, useCallWithGasPrice } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { extractError, waitForTransaction, calculateGasMargin } from 'shared/lib';
import { logger } from 'shared/lib/logger';
import { notifyError } from 'shared/lib/notifications';

import { useSwapRouterAddress } from './useSwapRouterAddress';

type AllowanceArgs = {
  address: string;
  amount: number | string;
};

export const useAllowance = ({ amount = 0, address }: AllowanceArgs) => {
  const { address: user } = useAccount();
  const { network: currentNetwork, chainId } = useNetwork();

  const { data: tokenData } = useSwapTokenByAddress(address as `0x${string}`);

  const routerAddress = useSwapRouterAddress();
  const publicClient = usePublicClient();

  const isNativeToken = address === NATIVE_TOKEN_ADDRESS;
  const allowanceCheckEnabled = !!user && !!tokenData;

  const {
    data: allowanceData,
    isError: isAllowanceError,
    isFetching: isAllowanceFetching,
    isLoading: isAllowanceLoading,
    isSuccess: isAllowanceSuccess,
    refetch: allowanceRefetch,
  } = useContractRead({
    abi: tokenAbi,
    address: address as `0x${string}`,
    args: [user!, routerAddress],
    chainId: currentNetwork?.id,
    enabled: allowanceCheckEnabled && !isNativeToken,
    functionName: 'allowance',
    onError: (e) => {
      notifyError({ text: extractError(e), title: 'Allowance' });
    },
    scopeKey: `allowance::${currentNetwork?.id}`,
  });

  const allowanceAmount = BigInt(allowanceData || '0');

  const inputAmountToCheck =
    amount && address
      ? parseUnits(`${amount}` as `${number}`, tokenData ? tokenData.decimals : 18) // the amount user entered
      : BigInt(1); // the minimum amount to check

  const needApprove =
    allowanceCheckEnabled && isAllowanceSuccess && inputAmountToCheck > allowanceAmount && !isNativeToken;

  const [gasForApprove, setGasForApprove] = useState<bigint | undefined>();

  const prepareArgs = {
    abi: tokenAbi,
    address: address as `0x${string}`,
    args: [routerAddress, BigInt(2) ** BigInt(256) - BigInt(1)],
    functionName: 'approve',
    account: user!,
  } as const;

  const { callWithGasPrice } = useCallWithGasPrice();

  const { config } = usePrepareContractWrite({
    ...prepareArgs,
    chainId: currentNetwork?.id,
    enabled: needApprove,
    cacheTime: 0,
    scopeKey: `approve::${currentNetwork?.id}`,
    onSuccess: async (data) => {
      if (data.request.gas) {
        return setGasForApprove(calculateGasMargin(data.request.gas));
      }
      try {
        const gasLimit = await publicClient.estimateContractGas(prepareArgs);
        const gas = calculateGasMargin(gasLimit);
        setGasForApprove(gas);
      } catch (e) {
        setGasForApprove(undefined);
        logger.error(e);
      }
    },
  });

  const {
    isLoading: isApproveContractLoading,
    reset,
    writeAsync: requestApprove,
  } = useContractWrite(config.request ? { ...config, request: { ...config.request, gas: gasForApprove } } : config);

  const [isApproveTransactionLoading, setIsApproveTransactionLoading] = useState(false);

  const isDoubleApprove = checkDoubleApproveToken(chainId, address) && allowanceAmount !== BigInt(0);

  if (address === NATIVE_TOKEN_ADDRESS)
    return {
      isAllowanceLoading: false,
      isApproveLoading: false,
      isError: false,
      isShowAprove: false,
      needApprove: false,
      requestApprove: () => {},
      isDoubleApprove: false,
    };

  const requestUserApprove = async (
    trackParams: TrackFunctionProperties<Event.SWAP_APPROVE_ATTEMPTED>,
    isDoubleApprove: boolean,
    amount?: bigint,
  ) => {
    const fnApprove =
      !isDoubleApprove && requestApprove
        ? requestApprove
        : callWithGasPrice.bind(
            null,
            {
              abi: prepareArgs.abi,
              address: prepareArgs.address as `0x${string}`,
            },
            'approve',
            [routerAddress, amount],
          );

    try {
      setIsApproveTransactionLoading(true);

      const transactionResult = await waitForUserAction(fnApprove?.());
      track(Event.SWAP_APPROVE_ATTEMPTED, trackParams);

      await waitForTransaction(waitForTransactionWagmi({ hash: transactionResult.hash }), transactionResult.hash);
      track(Event.SWAP_APPROVE_SUCCEEDED, trackParams);
      allowanceRefetch();

      if (isDoubleApprove) {
        await requestUserApprove(trackParams, false, BigInt(2) ** BigInt(256) - BigInt(1));
      }
    } catch (error: any) {
      allowanceRefetch();
      reset();
      logger.error(error);

      track(Event.SWAP_APPROVE_FAILED, {
        ...trackParams,
        error: error?.message ?? 'Unknown error',
      });
    } finally {
      setIsApproveTransactionLoading(false);
    }
  };

  const approve = async (trackParams: TrackFunctionProperties<Event.SWAP_APPROVE_ATTEMPTED>) => {
    await requestUserApprove(trackParams, isDoubleApprove, BigInt(0));
  };

  return {
    isAllowanceLoading: allowanceCheckEnabled ? isAllowanceLoading : false,
    isApproveLoading: isApproveContractLoading || isAllowanceFetching || isApproveTransactionLoading,
    isError: isAllowanceError,
    isShowAprove: needApprove,
    needApprove,
    requestApprove: approve,
    isDoubleApprove,
  };
};
