import { useMediaQuery } from '@mui/material';
import React, { isValidElement, useCallback } from 'react';

import { CheckIcon, LanguageEnIcon } from 'shared/assets';
import { LANGUAGES_ICONS } from 'shared/locales';
import { Box, Text } from 'shared/ui';

export interface LanguageItemProps {
  active: boolean;
  changeLanguage: (lang: string) => void;
  lang: string;
  title: string;
  toggle: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LanguageItem: React.FC<LanguageItemProps> = ({ active, changeLanguage, lang, title, toggle }) => {
  const handlePress = useCallback(() => {
    changeLanguage(lang);
    toggle(false);
  }, [changeLanguage, lang, toggle]);

  const Icon = LANGUAGES_ICONS[lang];
  const icon = Icon && <Icon width={24} height={24} />;

  const isSmallMobile = useMediaQuery('(max-width: 390px)', { noSsr: true });

  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      height={64}
      paddingX={24}
      paddingY={20}
      onClick={handlePress}
      color={active ? 'primary-01' : 'secondary-03'}
      border="0.5px solid"
      borderColor="secondary-02"
      backgroundColor={active ? 'secondary-02' : undefined}
      flex="1 0 0"
      minWidth="195px"
      maxWidth={isSmallMobile ? '100%' : '50%'}
    >
      <Box flexDirection="row" alignItems="center" gap={8}>
        {isValidElement(icon) ? icon : <LanguageEnIcon width={24} height={24} />}
        <Text text="app-14-medium" color={active ? 'primary-01' : 'white-01'}>
          {title}
        </Text>
      </Box>
      {active && <CheckIcon width={24} height={24} />}
    </Box>
  );
};
