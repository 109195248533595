import { i18n } from 'shared/locales';

export const SIGNATURE_MESSAGE =
  "Welcome to ReHold!\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nIt's needed to authenticate your wallet address.";

export const DEFAULT_ERROR = i18n.t('common.wrong');
export const VALIDATION_ERROR = 'VALIDATION_ERROR';
export const RECAPTCHA_ERROR = 'Recaptcha not passed';
export const UNKNOWN_ERROR = 'Unknown error';

export enum PathPatterns {
  Buy = '/buy',
  ClaimedDuals = '/duals/claimed/:id',
  Dual = '/dual/:chainId/:baseTicker/:quoteTicker',
  Duals = '/duals',
  DualsDashboard = '/dual',
  PageNotFound = '*',
  Perpetuals = '/trade',
  Points = '/airdrop',
  ReferralProgramMain = '/referral',
  ReferralProgramReferrals = '/referral/stats',
  Swap = '/swap',
}

export const MAX_INT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export const MAX_INPUT_VALUE = 9999999999999998;

// Local Storage Keys
export const LOCAL_STORAGE_HIDDEN_TRANSFERS = 'REHOLD_HIDDEN_TRANSFERS';
export const LOCAL_STORAGE_PENDING_TRANSFERS = 'REHOLD_PENDING_TRANSFERS';
export const LOCAL_STORAGE_MOCK_ADDRESS_KEY = 'REHOLD_MOCK_ADDRESS';
export const LOCAL_STORAGE_BASE_URL_KEY = 'REHOLD_BASE_URL';
export const LOCAL_STORAGE_CHART_SETTINGS = 'REHOLD_CHART_SETTINGS';
export const LOCAL_STORAGE_SURVEY_CACHE = 'REHOLD_SURVEY_CACHE';
