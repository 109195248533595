import { useMediaQuery } from '@mui/material';
import throttle from 'lodash.throttle';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePerpetualForm } from 'entities/Perpetual/lib/hooks';

import { ExpandIcon } from 'shared/assets';
import { useNetwork } from 'shared/hooks/network';
import { Box, Modal, Text } from 'shared/ui';

import { PerpetualsActionContent } from './PerpetualsActionContent';
import { PerpetualsTokenSelectModal } from './PerpetualsTokenSelectModal';
import './index.scss';
import { PerpetualsBalanceAction } from './types';

type Props = {
  onClose: () => void;
  onOpen: () => void;
  open: boolean;
  type: PerpetualsBalanceAction;
};

export const PerpetualsFormModal: React.FC<Props> = ({ onClose, open, type }) => {
  const { t } = useTranslation();

  const { chainId } = useNetwork();

  const { state, changeInput } = usePerpetualForm();

  const [selectOpen, setSelectOpen] = useState(false);

  const [tokenChain, setTokenChain] = useState(chainId);

  const isWideMobile = useMediaQuery('(max-width: 575px)', { noSsr: true });

  const containerRef = useRef<HTMLDivElement>(null);
  const actionBoxRef = useRef<HTMLDivElement>(null);
  const selectionBoxRef = useRef<HTMLDivElement>(null);

  const updateHeight = () => {
    if (containerRef.current && (actionBoxRef.current || selectionBoxRef.current)) {
      const height = `${actionBoxRef.current?.scrollHeight || selectionBoxRef.current?.scrollHeight}px`;
      containerRef.current.style.height = height;
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      updateHeight();
    }, 0);
    const resizeObserver = new ResizeObserver(throttle(() => updateHeight(), 100));
    if (containerRef.current) resizeObserver.observe(containerRef.current);
    if (actionBoxRef.current) resizeObserver.observe(actionBoxRef.current);
    if (selectionBoxRef.current) resizeObserver.observe(selectionBoxRef.current);

    return () => {
      resizeObserver.disconnect();
      clearInterval(timeoutId);
    };
  }, [open, selectOpen, containerRef.current, actionBoxRef.current, selectionBoxRef.current]);

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setSelectOpen(false);
    }, 200);
  };

  return (
    <>
      <Modal
        isOpen={open}
        onClose={handleClose}
        maxWidth={isWideMobile ? '100%' : 500}
        closeIcon
        header={
          <Box>
            {!selectOpen && (
              <Box height={24} px={22}>
                <Text text="app-18-medium" style={{ position: 'absolute', bottom: 0 }}>
                  {t(`modals.perpetuals.${type}.title`, { ticker: state.inputTicker.toUpperCase() })}
                </Text>
              </Box>
            )}
            {selectOpen && (
              <Box height={24} px={22} display="flex" flexDirection="row" gap="24px">
                <ExpandIcon
                  className="perpetuals-modal-token-select-back-button"
                  onClick={() => setSelectOpen(false)}
                />
                <Text text="app-18-medium">{t(`modals.perpetuals.${type}.selectTitle`)}</Text>
              </Box>
            )}
          </Box>
        }
      >
        <Box ref={containerRef} className="perpetuals-form-modal-container">
          <Box ref={actionBoxRef} display={selectOpen ? 'none' : undefined}>
            <PerpetualsActionContent
              type={type}
              onClose={handleClose}
              onSelectionOpen={() => setSelectOpen(true)}
              onTokenChainUpdate={setTokenChain}
              tokenChain={tokenChain}
            />
          </Box>
          <Box ref={selectionBoxRef} display={!selectOpen ? 'none' : undefined}>
            <PerpetualsTokenSelectModal
              type={type}
              onSelect={(chain, ticker) => {
                setTokenChain(chain);
                changeInput(ticker);
                setSelectOpen(false);
              }}
              selected={tokenChain}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};
