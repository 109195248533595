import { useMediaQuery } from '@mui/material';
import React, { useCallback, FC, isValidElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ForwardIcon, LanguageEnIcon, WalletIcon } from 'shared/assets';
import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { shortenIfAddress } from 'shared/lib';
import { DEFAULT_LANGUAGE, LANGUAGES_ICONS, SUPPORTED_LANGUAGES } from 'shared/locales';
import { Text } from 'shared/ui';
import { Box } from 'shared/ui/Box';

import { Page } from '../../types';

interface RootPageProps {
  onChangePage: (page: Page) => void;
}

export const RootPage: FC<RootPageProps> = ({ onChangePage }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const { network } = useNetwork();
  const { address, isConnected } = useAccount();

  const isSmallMobile = useMediaQuery('(max-width: 420px)', { noSsr: true });

  const handleLanguageClick = useCallback(() => onChangePage('language'), [onChangePage]);
  const handleNetworkClick = useCallback(() => onChangePage('network'), [onChangePage]);
  const handleWalletClick = useCallback(() => onChangePage('wallet'), [onChangePage]);

  const Icon = LANGUAGES_ICONS[language];
  const icon = Icon && <Icon width="100%" height="100%" />;

  return (
    <Box overflow="auto" flexBasis="0px" flexGrow="1">
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        height={72}
        padding={24}
        borderBottom="1px solid"
        borderColor="secondary-02"
        onClick={handleLanguageClick}
      >
        <Box flexDirection="row" alignItems="center">
          <Box width={24} height={24} mr={8}>
            {isValidElement(icon) ? icon : <LanguageEnIcon width="100%" height="100%" />}
          </Box>
          <Text>{SUPPORTED_LANGUAGES[language] ?? SUPPORTED_LANGUAGES[DEFAULT_LANGUAGE]}</Text>
        </Box>
        <Box width={16} height={16} color="white-01" onClick={handleLanguageClick}>
          <ForwardIcon width="100%" height="100%" strokeWidth={2} />
        </Box>
      </Box>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        height={72}
        padding={24}
        borderBottom="1px solid"
        borderColor="secondary-02"
        onClick={handleNetworkClick}
      >
        <Box flexDirection="row" alignItems="center">
          <Box width={24} height={24} mr={8}>
            {network?.renderLogo(24)}
          </Box>
          <Text>{network?.name}</Text>
        </Box>
        <Box width={16} height={16} color="white-01" onClick={handleNetworkClick}>
          <ForwardIcon width="100%" height="100%" strokeWidth={2} />
        </Box>
      </Box>
      {isConnected && isSmallMobile && (
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          height={72}
          padding={24}
          borderBottom="1px solid"
          borderColor="secondary-02"
          onClick={handleWalletClick}
        >
          <Box flexDirection="row" alignItems="center">
            <Box width={24} height={24} mr={8}>
              <WalletIcon width={24} height={24} />
            </Box>
            <Text>{shortenIfAddress(address, 4)}</Text>
          </Box>
          <Box width={16} height={16} color="white-01" onClick={handleWalletClick}>
            <ForwardIcon width="100%" height="100%" strokeWidth={2} />
          </Box>
        </Box>
      )}
    </Box>
  );
};
