import { Event, track } from '@rehold-io/data-layer-client';
import { getAmountPrecisionBySymbol } from '@rehold-io/formatters';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberFormatValues, NumericFormat, OnValueChange } from 'react-number-format';

import { Card } from 'shared/ui/Card';

import { TokenSelect } from '../TokenSelect';

import styles from './styles.module.scss';

interface AmountHandlerProps {
  amount: string;
  chainId: number;
  className?: string;
  onAmountChange: (amount: string, ticker: string) => void;
  ticker: string;
  tickers: string[];
}

const MAX_VAL = 9999999999999998;

export const AmountWithTicker: FC<AmountHandlerProps> = ({
  amount,
  className,
  onAmountChange,
  ticker,
  tickers,
  chainId,
}) => {
  const { t } = useTranslation();

  const handleAmountChange: OnValueChange = (values) => {
    onAmountChange(values.value, ticker);
  };

  const handleTickerChange = (nextTicker: string) => {
    onAmountChange(amount, nextTicker);

    track(Event.DUAL_INPUT_SELECTED, {
      ticker: nextTicker,
    });
  };

  const withValueLimit = ({ floatValue }: NumberFormatValues) => (floatValue ?? 0) <= MAX_VAL;

  return (
    <div className={className}>
      <Card variant="secondary" label={t('common.amount')} isOutlined className={className}>
        <div className={styles.cardContent}>
          <NumericFormat
            data-id="input-amount"
            className={styles.input}
            displayType="input"
            type="text"
            inputMode="decimal"
            onValueChange={handleAmountChange}
            value={amount}
            isAllowed={withValueLimit}
            allowNegative={false}
            allowLeadingZeros={false}
            decimalScale={getAmountPrecisionBySymbol(ticker)}
            allowedDecimalSeparators={['.', ',']}
            defaultValue={0}
            valueIsNumericString
          />
          <TokenSelect tickers={tickers} selected={ticker} onChange={handleTickerChange} chainId={chainId} />
        </div>
      </Card>
    </div>
  );
};
