export const THEME_TYPOGRAPHY = {
  'app-10-medium': {
    fontSize: 'var(--typography-10-medium-font-size)',
    fontWeight: 'var(--typography-10-medium-font-weight)',
    lineHeight: 'var(--typography-10-medium-line-height)',
  },
  'app-11-regular': {
    fontSize: 'var(--typography-11-regular-font-size)',
    fontWeight: 'var(--typography-11-regular-font-weight)',
    lineHeight: 'var(--typography-11-regular-line-height)',
  },
  'app-12-medium': {
    fontSize: 'var(--typography-12-medium-font-size)',
    fontWeight: 'var(--typography-12-medium-font-weight)',
    lineHeight: 'var(--typography-12-medium-line-height)',
  },
  'app-12-regular': {
    fontSize: 'var(--typography-12-regular-font-size)',
    fontWeight: 'var(--typography-12-regular-font-weight)',
    lineHeight: 'var(--typography-12-regular-line-height)',
  },
  'app-14-medium': {
    fontSize: 'var(--typography-14-medium-font-size)',
    fontWeight: 'var(--typography-14-medium-font-weight)',
    lineHeight: 'var(--typography-14-medium-line-height)',
  },
  'app-14-regular': {
    fontSize: 'var(--typography-14-regular-font-size)',
    fontWeight: 'var(--typography-14-regular-font-weight)',
    lineHeight: 'var(--typography-14-regular-line-height)',
  },
  'app-16-medium': {
    fontSize: 'var(--typography-16-medium-font-size)',
    fontWeight: 'var(--typography-16-medium-font-weight)',
    lineHeight: 'var(--typography-16-medium-line-height)',
  },
  'app-18-medium': {
    fontSize: 'var(--typography-18-medium-font-size)',
    fontWeight: 'var(--typography-18-medium-font-weight)',
    lineHeight: 'var(--typography-18-medium-line-height)',
  },
  'app-22-medium': {
    fontSize: 'var(--typography-22-medium-font-size)',
    fontWeight: 'var(--typography-22-medium-font-weight)',
    lineHeight: 'var(--typography-22-medium-line-height)',
  },
  'app-26-medium': {
    fontSize: 'var(--typography-26-medium-font-size)',
    fontWeight: 'var(--typography-26-medium-font-weight)',
    lineHeight: 'var(--typography-26-medium-line-height)',
  },
};
