import { Event, track } from '@rehold-io/data-layer-client';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useGetNotificationCountQuery } from 'features/Notifications/model/useGetNotificationCountQuery';
import { NotificationCenter } from 'features/Notifications/ui';

import { BellIcon } from 'shared/assets';
import { useAccount, useToggle } from 'shared/hooks';
import { Box } from 'shared/ui';

import styles from './styles.module.scss';

export const NavNotifications = observer(() => {
  const { isConnected } = useAccount();
  const location = useLocation();
  const [isOpen, toggle, setOpen] = useToggle();
  const { data } = useGetNotificationCountQuery({});
  const count = useRef<number>();

  if (data?.unread !== undefined) {
    count.current = data.unread;
  }

  const handleOpen = () => {
    track(Event.MENU_CLICKED, { type: 'notifications', from: location.pathname });
    toggle();
  };

  if (!isConnected) return;

  return (
    <>
      <Box className={cx('menu-header-notifications', styles.trigger, { [styles.open]: isOpen })} onClick={handleOpen}>
        {!!count.current && (
          <Box
            width={10}
            height={10}
            backgroundColor="black-01"
            position="absolute"
            top={8}
            right={8}
            borderRadius="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Box width={8} height={8} backgroundColor="red-01" borderRadius="100%" />
          </Box>
        )}
        <BellIcon width={24} height={24} />
      </Box>
      <NotificationCenter isOpen={isOpen} onClose={() => setOpen(false)} />
    </>
  );
});
