/* eslint-disable no-await-in-loop */
import { Event, track } from '@rehold-io/data-layer-client';
import { toast } from 'react-hot-toast';
import { Log, decodeEventLog } from 'viem';
import { waitForTransaction } from 'wagmi/actions';

import { UserTransfer, storageTransfers } from 'entities/User';
import { getTransfer } from 'entities/User/api/queries';
import { GetTransferResponse, TransferStatus } from 'entities/User/model/types';

import { tokenAbi } from 'shared/config';
import { TransferToast } from 'shared/ui/Toasts/Transfer/TransferToast';

export const waitForTransfer = (value: UserTransfer) => {
  const { address, txHash, chainId, type, amount, ticker, timeout } = value;

  const storedHiddenTransfers = storageTransfers.getHiddenTransfers();

  const isHidden = storedHiddenTransfers.find((transfer) => transfer === txHash);

  storageTransfers.addPendingTransfer(value);

  if (!isHidden) {
    toast.custom(
      (t) => (
        <TransferToast
          toast={t}
          txHash={txHash}
          type={type}
          amount={amount}
          ticker={ticker}
          chainId={chainId}
          onClose={() => {
            storageTransfers.addHiddenTransfer(value);
            toast.remove(t.id);
          }}
        />
      ),
      {
        duration: Infinity,
        id: txHash,
      },
    );
  }

  const wait = async () => {
    const transactionReceipt = await waitForTransaction({ hash: txHash as `0x${string}`, chainId });

    const logIndex = getTransferLogIndex({
      from: type === 'DEPOSIT' ? address! : '',
      to: type === 'WITHDRAWAL' ? address! : '',
      logs: transactionReceipt.logs,
    });

    if (logIndex === null || logIndex === undefined) throw new Error('No log index was spotted in the transfer logs');

    let failed = 0;
    let transfer;

    try {
      transfer = await getTransfer({ chainId, logIndex, txHash });
    } catch (e: any) {
      failed += 1;
    }

    while (!transfer || transfer.status === TransferStatus.PENDING) {
      if (failed >= 60) throw new Error('Transfer does not exist');

      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });

      try {
        transfer = await getTransfer({ chainId, logIndex, txHash });
      } catch (e: any) {
        failed += 1;
      }
    }

    return transfer;
  };

  let timeoutId: NodeJS.Timeout | undefined;

  const timeoutPromise = new Promise<GetTransferResponse>((_, reject) => {
    timeoutId = setTimeout(() => {
      reject(new Error('Transfer timeout'));
    }, timeout || 5 * 60 * 1000);
  });

  return Promise.race([wait(), timeoutPromise])
    .then((transfer) => {
      if (transfer.status === TransferStatus.DROPPED || transfer.status === TransferStatus.REVERTED) {
        throw new Error('Transfer failed');
      }

      const trackParams = {
        address,
        chainId,
        amount,
        ticker,
      };
      if (value.type === 'WITHDRAWAL') {
        track(Event.PERPETUAL_WITHDRAW_SUCCEEDED, trackParams);
      }
      if (value.type === 'DEPOSIT') {
        track(Event.PERPETUAL_DEPOSIT_SUCCEEDED, trackParams);
      }

      storageTransfers.removePendingTransfer(value);
      storageTransfers.removeHiddenTransfer(value);

      toast.custom(
        (t) => (
          <TransferToast
            toast={t}
            status="success"
            txHash={transfer.txHash}
            onClose={() => {
              toast.remove(t.id);
            }}
            chainId={chainId}
            type={type}
            amount={amount}
            ticker={ticker}
          />
        ),
        {
          duration: 3000,
          id: txHash,
        },
      );
      return transfer;
    })
    .catch((e) => {
      storageTransfers.removePendingTransfer(value);
      storageTransfers.removeHiddenTransfer(value);

      toast.custom(
        (t) => (
          <TransferToast
            toast={t}
            status="error"
            txHash={txHash}
            onClose={() => {
              toast.remove(t.id);
            }}
            chainId={chainId}
            type={type}
            amount={amount}
            ticker={ticker}
          />
        ),
        {
          duration: 10000,
          id: txHash,
        },
      );

      throw e;
    })
    .finally(() => {
      if (timeoutId) clearTimeout(timeoutId);
    });
};

function getTransferLogIndex(props: { from?: string; logs: Log<bigint, number>[]; to?: string }) {
  let index = null;

  props.logs.forEach((log) => {
    const [topic] = log.topics;

    const {
      args: { from, to },
    } = decodeEventLog({ abi: tokenAbi, topics: log.topics, eventName: 'Transfer', data: log.data });

    if (
      topic === '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef' &&
      props.from &&
      props.from.toLowerCase() === from.toLowerCase()
    ) {
      index = log.logIndex;
    }

    if (
      topic === '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef' &&
      props.to &&
      props.to.toLowerCase() === to.toLowerCase()
    ) {
      index = log.logIndex;
    }
  });

  return index as null | number;
}
