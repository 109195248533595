import { Event, track } from '@rehold-io/data-layer-client';
import { useQueryClient } from '@tanstack/react-query';
import { BigNumber } from 'bignumber.js';
import { useDeferredValue, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { waitForTransaction as waitForTransactionWagmi } from 'wagmi/actions';

import { useAutoReplay, useSetAutoReplayMutation } from 'features/AutoReplay';
import { useCreate } from 'features/CreateDual';
import { useCreateWithPermit } from 'features/CreateDual/lib/useCreateWithPermit';
import { HotjarEvents, trackHotjar } from 'features/HotJar';
import { useLimitDualInfo } from 'features/LimitsDual';
import { useCreateLimitDual } from 'features/LimitsDual/lib/useCreateLimitDual';
import { updateFinishStep } from 'features/OnboardingTour';
import { storagePendingDuals } from 'features/PendingDual';

import { getCreatedDualInfo, isEqualTariff, useStakingPlans } from 'entities/Dual';
import { ratesStore } from 'entities/Rates';
import { getWrappedNativeToken } from 'entities/Token';
import { useGetDualTokenAddress } from 'entities/Token/model/useGetDualTokenAddress';
import { useGetDualTokensAllQuery } from 'entities/Token/model/useGetDualTokensAllQuery';
import { waitForUserAction } from 'entities/User';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { DEFAULT_ERROR, mapErrorToUserReadableMessage, checkTokenPermitSupport, waitForTransaction } from 'shared/lib';
import { logger } from 'shared/lib/logger';
import { notifyError } from 'shared/lib/notifications';

import { DashboardFormValues } from '../model/form';

import { useCalculateOutput } from './useCalculateOutput';

export const useSubmit = (data: DashboardFormValues) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { chainId: connectedChainId } = useNetwork();
  const { tokens } = useGetDualTokensAllQuery();
  const { changeAutoReplay } = useSetAutoReplayMutation();
  const { enabled: enabledAutoReplay } = useAutoReplay();

  const { assetId, inputAmount, inputTicker, tariff, chainId: assetChainId } = data;

  const { enabled: enabledLimitDual, initialPrice, isError: isErrorLimitDual, targetPrice } = useLimitDualInfo();

  const inputToken = useGetDualTokenAddress(inputTicker, assetChainId);
  const { output } = useCalculateOutput();
  const defferedInputAmount = useDeferredValue(inputAmount);

  const isSupportPermit = checkTokenPermitSupport(assetChainId, inputToken!);

  const {
    isPreparing,
    prepare: { error: createPrepareError, isLoading: isLoadingPrepare },
    write: { isLoading: isLoadingCreate, reset: resetCreate, writeAsync: createWriteAsync },
  } = useCreate({
    inputAmount: defferedInputAmount,
    inputTicker,
    tariff: tariff!,
    isDisabledPermit: enabledLimitDual,
    chainId: assetChainId,
  });

  const {
    isPreparing: isPreparingWithPermit,
    needApprove,
    prepare: { error: createPrepareErrorWithPermit, isLoading: isLoadingPrepareWithPermit },
    write: {
      isLoading: isLoadingCreateWithPermit,
      reset: resetCreateWithPermit,
      writeAsync: createWriteAsyncWithPermit,
    },
  } = useCreateWithPermit({
    inputAmount: defferedInputAmount,
    inputTicker,
    tariff: tariff!,
    isDisabledPermit: enabledLimitDual,
    chainId: assetChainId,
  });

  const plans = useStakingPlans({ assetId, chainId: assetChainId });
  const selectedPlan = useMemo(() => plans.find((plan) => isEqualTariff(plan, tariff)), [plans, tariff]);

  const { address } = useAccount();

  const isCreateWithPermit = isSupportPermit && needApprove && !enabledLimitDual;

  const getTrackParams = (values: DashboardFormValues) => {
    const from = selectedPlan?.baseTicker.toLowerCase();
    const to = selectedPlan?.quoteTicker.toLowerCase();
    const rate = ratesStore.getRate(`${from}/${to}`)!;
    const rateUSD = ratesStore.getRate(`${inputTicker}/usd`);
    return {
      address: address!,
      apr: selectedPlan!.apr,
      autoReplay: enabledAutoReplay,
      baseTicker: selectedPlan!.baseTicker,
      initialPrice: rate.formatted.price,
      inputAmount: values.inputAmount,
      inputAmountUSD: BigNumber(rateUSD?.price || 0)
        .multipliedBy(values.inputAmount)
        .toNumber(),
      inputTicker: values.inputTicker,
      quoteTicker: selectedPlan!.quoteTicker,
      stakingPeriod: selectedPlan!.stakingPeriod,
    };
  };

  const createDual = async (values: DashboardFormValues) => {
    if (!selectedPlan) {
      logger.error(new Error('not found plan'));
      return;
    }

    if (connectedChainId !== assetChainId) {
      notifyError({
        text: DEFAULT_ERROR,
      });
      logger.error(new Error('chain in the wallet does not match with dual chain'));
      return;
    }

    const writeAsync = isCreateWithPermit ? createWriteAsyncWithPermit : createWriteAsync;

    const from = selectedPlan.baseTicker.toLowerCase();
    const to = selectedPlan.quoteTicker.toLowerCase();
    const rate = ratesStore.getRate(`${from}/${to}`)!;

    const trackParams = getTrackParams(values);

    track(Event.DUAL_START_CLICKED, trackParams);
    trackHotjar(HotjarEvents.DUAL_START_CLICK);

    if (!writeAsync) {
      notifyError({
        text:
          mapErrorToUserReadableMessage(createPrepareError) ||
          mapErrorToUserReadableMessage(createPrepareErrorWithPermit) ||
          DEFAULT_ERROR,
      });
      const error = createPrepareError || createPrepareErrorWithPermit || new Error(DEFAULT_ERROR);
      logger.error(error);

      return;
    }

    try {
      const { hash } = await waitForUserAction(writeAsync());

      track(Event.DUAL_START_ATTEMPTED, trackParams);
      updateFinishStep('hide');

      const transactionReceipt = await waitForTransaction(waitForTransactionWagmi({ hash }), hash);
      track(Event.DUAL_START_SUCCEEDED, trackParams);
      trackHotjar(HotjarEvents.DUAL_START_SUCCESS);
      updateFinishStep();

      const finishedAt = new Date().getTime() + selectedPlan.stakingPeriod * 60 * 60 * 1000;
      const id = getCreatedDualInfo(transactionReceipt.logs);

      if (enabledAutoReplay) {
        changeAutoReplay(id || hash);
      }

      storagePendingDuals.setDual({
        address: address!,
        apr: selectedPlan.apr.toString(),
        baseTicker: selectedPlan.baseTicker,
        closeCalculate: {
          down: output?.down!,
          up: output?.up!,
        },
        finishAt: new Date(finishedAt).toISOString(),
        id,
        initialPrice: rate.formatted.price,
        inputAmount: values.inputAmount.toString(),
        inputTicker: values.inputTicker,
        l1TxHash: hash,
        quoteTicker: selectedPlan.quoteTicker,
        stakingPeriod: selectedPlan.stakingPeriod,
        startedAt: new Date().toISOString(),
        tariffId: values.tariff?.id!,
        chainId: connectedChainId!,
      });

      queryClient.invalidateQueries({
        queryKey: [`count-duals-${connectedChainId}-${address}`],
      });
      queryClient.invalidateQueries({
        queryKey: [`opened-duals-${connectedChainId}-${address}`],
      });
      navigate('/duals');
    } catch (error: any) {
      resetCreate();
      resetCreateWithPermit();

      updateFinishStep('show');

      track(Event.DUAL_START_FAILED, { ...trackParams, error: error?.message ?? 'Unknown error' });

      logger.error(error);
    }
  };

  const { createLimit } = useCreateLimitDual();

  const createDualLimit = async (values: DashboardFormValues) => {
    if (!selectedPlan) {
      logger.error(new Error('not found plan'));
      return;
    }

    if (connectedChainId !== assetChainId) {
      notifyError({
        text: DEFAULT_ERROR,
      });
      logger.error(new Error('chain in the wallet does not match with dual chain'));
      return;
    }

    const initialTrackParams = getTrackParams(values);
    const trackParams = initialTrackParams
      ? ({
          ...initialTrackParams,
          direction: Number(targetPrice) > initialPrice ? 'up' : 'down',
          initialPrice,
          targetPrice,
        } as const)
      : null;

    try {
      if (trackParams) {
        track(Event.LIMIT_DUAL_START_ATTEMPTED, trackParams);
      }
      updateFinishStep('hide');

      const wrappedToken = tokens?.[values.chainId]?.[getWrappedNativeToken(connectedChainId!)]?.address!;

      const inputToken = tokens?.[values.chainId]?.[values.inputTicker]?.address || wrappedToken;
      const baseToken = tokens?.[values.chainId]?.[selectedPlan.baseTicker]?.address || wrappedToken;
      const quoteToken = tokens?.[values.chainId]?.[selectedPlan.quoteTicker]?.address || wrappedToken;

      await createLimit({
        initialPrice: BigNumber(initialPrice).shiftedBy(18).toFixed(),
        inputAmount: BigNumber(values.inputAmount)
          .shiftedBy(tokens[values.chainId]?.[values.inputTicker]?.decimals!)
          .toFixed(),
        inputToken,
        baseToken,
        quoteToken,
        stakingPeriod: values.tariff?.stakingPeriod!,
        targetPrice: BigNumber(targetPrice).shiftedBy(18).toFixed(),
        yield: values.tariff?.yield!,
        chainId: assetChainId,
      });

      if (trackParams) {
        track(Event.LIMIT_DUAL_START_SUCCEEDED, trackParams);
        updateFinishStep();
      }

      queryClient.invalidateQueries({
        queryKey: [`opened-limit-${address}-${connectedChainId}`],
      });
      queryClient.invalidateQueries({
        queryKey: [`count-duals-${connectedChainId}-${address}`],
      });
      navigate('/duals/limit');
    } catch (error: any) {
      updateFinishStep('show');
      if (trackParams) {
        track(Event.LIMIT_DUAL_START_FAILED, { ...trackParams, error: error?.message ?? 'Unknown error' });
      }
      logger.error(error);
    }
  };

  if (isCreateWithPermit) {
    return {
      isAutoReplay: enabledAutoReplay,
      isDisabled: false,
      isError: createPrepareErrorWithPermit,
      isLoading: isLoadingCreateWithPermit || isLoadingPrepareWithPermit || isPreparingWithPermit,
      onSubmit: enabledLimitDual ? createDualLimit : createDual,
    };
  }

  return {
    isAutoReplay: enabledAutoReplay,
    isDisabled: enabledLimitDual ? isErrorLimitDual || !targetPrice : false,
    isError: createPrepareError,
    isLoading: isLoadingPrepare || isLoadingCreate || isPreparing,
    onSubmit: enabledLimitDual ? createDualLimit : createDual,
  };
};
